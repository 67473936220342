import { addResourceHint } from '../../../../base/extras-browser/resource-hints';

/**
 * Add resource hints for pre-connecting to iMOW SignalR negotiation endpoint.
 *
 * Preconnect performs DNS lookup + TCP handshake + establishing
 * TLS tunnel. Doing this in advance speeds up fetching data
 * from the API for the initial render.
 */
export function addImowSignalrResourceHint(): void {
  addResourceHint({
    type: 'preconnect',
    href: process.env.REACT_APP_IMOW_API_SIGNALR_HUB_ORIGIN as string,
    crossorigin: 'anonymous',
  });
}
