export function assertType<T>(
  value: unknown,
  isType: (value: unknown) => value is T,
  valueName: string,
  typeName: string,
): asserts value is T {
  if (!isType(value)) {
    throw new TypeError(
      `Expected value "${valueName}" to be of type: ${typeName}`,
    );
  }
}

export type TypeGuardPredicate<T> = (value: unknown) => value is T;

export function isTypeUnion<TPredicates extends TypeGuardPredicate<unknown>[]>(
  value: unknown,
  typePredicates: TPredicates,
): value is TPredicates[keyof TPredicates] {
  return typePredicates.some((isType) => isType(value));
}

export function assertTypeUnion<
  TPredicates extends TypeGuardPredicate<unknown>[],
>(
  value: unknown,
  typePredicates: TPredicates,
  valueName: string,
  typeNames: string[],
): asserts value is TPredicates[keyof TPredicates] {
  if (!isTypeUnion(value, typePredicates)) {
    throw new TypeError(
      `Expected value "${valueName}" to be one type of: ${typeNames.join(' ')}`,
    );
  }
}
