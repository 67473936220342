/**
 * @see https://datatracker.ietf.org/doc/html/rfc7519#section-4.1
 */
type TokenStandardClaims = {
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  nbf?: number;
  jti?: string;
};

/**
 * Access token used by STIHL WebSSO (Forgerock).
 */
export type StihlAccessToken = Required<TokenStandardClaims> & {
  cts: string;

  auth_level: number;
  token_type: string;
  scope: string[];
  expires_in: number;
  grant_type: string;
  auth_time: number;

  realm: string;

  auditTrackingId: string;
  authGrantId: string;
  tokenName: string;
};

/**
 * ID token used by STIHL WebSSO (Forgerock).
 *
 * @see https://darutk.medium.com/understanding-id-token-5f83f50fa02e
 */
export type IdToken = TokenStandardClaims & {
  acr?: string;
  azp?: string;
  auth_time: number;

  at_hash?: string;
  c_hash?: string;
  s_hash?: string;

  given_name?: string;
  family_name?: string;
  email?: string;
  email_verified?: boolean;
  locale?: string;

  language?: string;

  realm: string;
  user_licenses?: string;
  user_roles?: string;

  auditTrackingId: string;
  tokenName: string;
  tokenType: string;
};

/**
 * Refresh token used by STIHL WebSSO (Forgerock).
 */
export type StihlRefreshToken = Required<TokenStandardClaims> & {
  acr: string;
  cts: string;
  auth_time: number;

  token_type: string;
  auth_level: number;
  ops: string;
  grant_type: string;
  scope: string[];

  expires_in: number;
  realm: string;

  auditTrackingId: string;
  tokenName: string;
  authGrantId: string;
};

/**
 * Number of seconds elapsed since beginning of UNIX epoch on `1970-01-01T00:00:00.000Z`.
 */
export function getNowUnixTimestamp(): number {
  return Math.floor(Date.now() / 1000);
}

export function isRefreshTokenExpired(token: StihlRefreshToken): boolean {
  return getNowUnixTimestamp() > token.exp;
}
