import nameof from 'ts-nameof.macro';
import { assertNonNullable } from '../extras-typescript-asserts/type-non-nullable-asserts';
import { InvariantViolationError } from '../invariant/invariant-violation-error';
import type {
  CreateModulet,
  CreateModuletAny,
  ExportsOf,
  ExportsOfAll,
  ImportsOfAll,
  ModuletActivated,
  ModuletDeactivated,
  ModuletKey,
  ModuletMap,
} from './modulet';
import {
  activateModulet,
  createModuletMap,
  deactivateModulet,
  getModuletMapAllExports,
} from './modulet';
import type {
  ModuletExportsBase,
  ModuletImportsBase,
} from './modulet-imports-exports';
import type { ScopeBase } from './modulet-scope';
import {
  createChildScope,
  createEmptyScope,
  getScopeValue,
  toScopeSnapshot,
} from './modulet-scope';

export type DeactivateWithKeepaliveResult = 'deactivated' | 'reactivated';
export const DeactivateWithKeepaliveResults = {
  deactivated: 'deactivated' as const,
  reactivated: 'reactivated' as const,
};

/**
 * @overview
 * A {@link ModuletContainer} registers and manages one or multiple {@link ModuletInstance}s.
 *
 * It keeps a registry of activated modulets using a key (by default the modulets'
 * factory function) and maintains a [scope]{@link ScopeBase} that contains
 * all `export`s of those activated modulets. A modulet is activated in
 * a container using its factory function. The factory receives the current
 * scope – hence if a modulet depends on the exports of another the latter
 * modulet has to be activated first so its `exports` are in scope.
 * During activation the container instantiates the modulet by calling its
 * factory and invokes `activate()`. The container then provides
 * methods to manage all modulet instances e.g. to
 * [`await whenAllActivated()`]{@link ModuletContainer#whenAllActivated}
 * or [`deactivateAll()`]{@link ModuletContainer#deactivateAll}.
 *
 * Containers may form a tree structure where every container has its own
 * modulet registry and scope, with the scope inheriting the exports of its
 * parent container – exports with the same name are overwritten by the child container.
 *
 * To use a contained `value` that a modulet contributed to the `scope`
 * via its `exports` @link ModuletContainer#getValue} is used. This includes
 * values from any parent scope.
 */
export type ModuletContainer<
  TExportsAll extends ModuletExportsBase,
  TScopeOfAll extends TExportsAll = TExportsAll,
> = {
  activate: <
    TImports extends ModuletImportsBase,
    TExports extends ModuletExportsBase,
  >(
    create: CreateModulet<TImports, TExports>,
    key?: ModuletKey<TImports, TExports>,
  ) => ModuletActivated<TImports, TExports>;
  deactivate: <
    TImports extends ModuletImportsBase,
    TExports extends ModuletExportsBase,
  >(
    key: ModuletKey<TImports, TExports>,
  ) => ModuletDeactivated<TImports, TExports> | undefined;

  deactivateAll: () => Promise<void>;
  deactivateAllWithKeepalive: (
    milliseconds: number,
  ) => Promise<DeactivateWithKeepaliveResult>;

  whenAllActivated: () => Promise<void>;
  whenAllDeactivated: () => Promise<void>;

  getValue: <TExportName extends keyof TScopeOfAll>(
    exportName: TExportName,
  ) => NonNullable<TScopeOfAll[TExportName]>;
  getAllValues: () => TScopeOfAll;

  getChild: <TChildExports extends ModuletExportsBase>(
    key: ModuletContainerKey,
  ) => ModuletContainer<
    TExportsAll & TChildExports,
    TScopeOfAll & TChildExports
  >;
  removeChild: <TChildExports extends ModuletExportsBase>(
    key: ModuletContainerKey,
  ) =>
    | ModuletContainer<TExportsAll & TChildExports, TScopeOfAll & TChildExports>
    | undefined;

  /**
   * Casts the containers type so that it's scope contains the `exports`
   * of passed module factories.
   *
   * Use after {@link ModuletContainer#activate} and {@link ModuletContainer#deactivate}
   * to adjust the type accordingly.
   *
   * @param moduletFactories
   */
  asContainerOf: <TModuletFactories extends CreateModuletAny[]>(
    ...moduletFactories: TModuletFactories
  ) => ModuletContainer<
    TExportsAll & ExportsOf<TModuletFactories[number]>,
    TScopeOfAll & ExportsOf<TModuletFactories[number]>
  >;
};

type ModuletContainerState<
  TAllImports extends ModuletImportsBase,
  TAllExports extends ModuletExportsBase,
  TScope extends TAllExports = TAllExports,
  TChildrenContainerMap extends ModuletContainerMap = ModuletContainerMap,
> = {
  modulets: ModuletMap<TAllImports, TAllExports>;
  scope: TScope;
  children: TChildrenContainerMap;
};

export type ModuletContainerKey =
  | ((...args: never) => unknown)
  // allow object as keys e.g. `[create1, create2]` array of activated modulet factories
  // eslint-disable-next-line @typescript-eslint/ban-types
  | object
  | PropertyKey;

type ModuletContainerMap<
  TAllExports extends ModuletExportsBase = ModuletExportsBase,
  TScope extends TAllExports = TAllExports,
> = Map<ModuletContainerKey, ModuletContainer<TAllExports, TScope>>;

function createModuletContainerMap<
  TAllExports extends ModuletExportsBase,
  TScope extends TAllExports = TAllExports,
>(): ModuletContainerMap<TAllExports, TScope> {
  return new Map<ModuletContainerKey, ModuletContainer<TAllExports, TScope>>();
}

function* whenAllActivationsFrom<
  TAllImports extends ModuletImportsBase,
  TAllExports extends ModuletExportsBase,
>(modulets: ModuletMap<TAllImports, TAllExports>): Iterable<Promise<void>> {
  for (const modulet of modulets.values()) {
    if (modulet.whenActivated) {
      yield modulet.whenActivated;
    }
  }
}
async function whenAllActivated<
  TAllImports extends ModuletImportsBase,
  TAllExports extends ModuletExportsBase,
>(modulets: ModuletMap<TAllImports, TAllExports>): Promise<void> {
  await Promise.all(whenAllActivationsFrom(modulets));
}

function* whenAllDeactivationsFrom<
  TAllImports extends ModuletImportsBase,
  TAllExports extends ModuletExportsBase,
>(modulets: ModuletMap<TAllImports, TAllExports>): Iterable<Promise<void>> {
  for (const modulet of modulets.values()) {
    if (modulet.whenDeactivated) {
      yield modulet.whenDeactivated;
    }
  }
}
async function whenAllDeactivated<
  TAllImports extends ModuletImportsBase,
  TAllExports extends ModuletExportsBase,
>(modulets: ModuletMap<TAllImports, TAllExports>): Promise<void> {
  await Promise.all(whenAllDeactivationsFrom(modulets));
}

function* allDeactivationsFrom<
  TAllImports extends ModuletImportsBase,
  TAllExports extends ModuletExportsBase,
  TScope extends TAllExports = TAllExports,
>(
  modulets: ModuletMap<TAllImports, TAllExports>,
  scope: TScope,
): Iterable<Promise<void>> {
  for (const modulet of modulets.values()) {
    if (modulet.deactivate) {
      const deactivatedModule = deactivateModulet(modulets, scope, modulet.key);
      assertNonNullable(deactivatedModule, nameof(deactivatedModule));
      yield deactivatedModule.whenDeactivated;
    }
  }
}
async function deactivateAllModulets<
  TAllImports extends ModuletImportsBase,
  TAllExports extends ModuletExportsBase,
  TScope extends TAllExports = TAllExports,
>(
  modulets: ModuletMap<TAllImports, TAllExports>,
  scope: TScope,
): Promise<void> {
  await Promise.all(allDeactivationsFrom(modulets, scope));
}

type CreateModuletContainerOptions<TScope extends ScopeBase> = {
  scope?: TScope;
};

// function serves as scope hence needs more lines
// eslint-disable-next-line max-lines-per-function
export function createModuletContainer<
  TImportsAll extends ModuletImportsBase,
  TExportsAll extends ModuletExportsBase,
  TScopeOfAll extends TExportsAll = TExportsAll,
  TChildrenContainerMap extends ModuletContainerMap = ModuletContainerMap,
>({
  scope = createEmptyScope<TScopeOfAll>(),
}: CreateModuletContainerOptions<TScopeOfAll> = {}): ModuletContainer<
  TExportsAll,
  TScopeOfAll
> {
  const contained: ModuletContainerState<
    TImportsAll,
    TExportsAll,
    TScopeOfAll
  > = {
    scope,
    modulets: createModuletMap<TImportsAll, TExportsAll>(),
    children: createModuletContainerMap() as TChildrenContainerMap,
  };

  type PendingDeactivateAllWithKeepalive = {
    cancelDueReactivate: () => void;
    forceDeactivateImmediate: () => Promise<void>;
    promise: Promise<DeactivateWithKeepaliveResult>;
    restartTimeout: (
      milliseconds: number,
    ) => Promise<DeactivateWithKeepaliveResult>;
  };
  let pendingDeactivateAllWithKeepalive:
    | PendingDeactivateAllWithKeepalive
    | undefined;

  function getChild<TChildExports extends ModuletExportsBase>(
    key: ModuletContainerKey,
  ): ModuletContainer<
    TExportsAll & TChildExports,
    TScopeOfAll & TChildExports
  > {
    let childContainer = contained.children.get(key);
    if (!childContainer) {
      childContainer = createModuletContainer({
        scope: createChildScope(
          scope,
          getModuletMapAllExports(contained.modulets),
        ),
      });
      contained.children.set(key, childContainer);
    }

    return childContainer as ModuletContainer<
      TExportsAll & TChildExports,
      TScopeOfAll & TChildExports
    >;
  }
  function removeChild<TChildExports extends ModuletExportsBase>(
    key: ModuletContainerKey,
  ):
    | ModuletContainer<TExportsAll & TChildExports, TScopeOfAll & TChildExports>
    | undefined {
    const childContainer = contained.children.get(key) as
      | ModuletContainer<
          TExportsAll & TChildExports,
          TScopeOfAll & TChildExports
        >
      | undefined;
    if (childContainer) {
      contained.children.delete(key);
    }

    return childContainer;
  }

  const moduletContainer = {
    activate: <
      TImports extends ModuletImportsBase,
      TExports extends ModuletExportsBase,
    >(
      create: CreateModulet<TImports, TExports>,
      key: ModuletKey<TImports, TExports> = create,
    ): ModuletActivated<TImports, TExports> => {
      pendingDeactivateAllWithKeepalive?.cancelDueReactivate();

      return activateModulet(
        contained.modulets as unknown as ModuletMap<
          TImports,
          TExportsAll & TExports
        >,
        contained.scope as unknown as NonNullable<TImports>,
        create,
        key,
      );
    },
    deactivate: <
      TImports extends ModuletImportsBase,
      TExports extends ModuletExportsBase,
    >(
      key: ModuletKey<TImports, TExports>,
    ): ModuletDeactivated<TImports, TExports> | undefined => {
      if (pendingDeactivateAllWithKeepalive) {
        throw new Error(
          `Illegal deactivation of modulet ${String(
            key,
          )}: a removal with keepalive is pending.`,
        );
      }

      return deactivateModulet(
        contained.modulets as unknown as ModuletMap<
          TImports,
          TExportsAll & TExports
        >,
        contained.scope as unknown as TExports,
        key,
      );
    },

    deactivateAll: async (): Promise<void> => {
      if (pendingDeactivateAllWithKeepalive) {
        return pendingDeactivateAllWithKeepalive.forceDeactivateImmediate();
      }

      return deactivateAllModulets(contained.modulets, contained.scope);
    },

    // eslint-disable-next-line max-lines-per-function
    deactivateAllWithKeepalive: async (
      milliseconds: number,
    ): Promise<DeactivateWithKeepaliveResult> => {
      if (pendingDeactivateAllWithKeepalive) {
        return pendingDeactivateAllWithKeepalive.restartTimeout(milliseconds);
      }

      const newPendingDeactivateAllWithKeepalive = {
        promise: undefined,
        cancelDueReactivate: undefined,
        forceDeactivateImmediate: undefined,
        restartTimeout: undefined,
      } as unknown as PendingDeactivateAllWithKeepalive;
      pendingDeactivateAllWithKeepalive = newPendingDeactivateAllWithKeepalive;

      newPendingDeactivateAllWithKeepalive.promise =
        new Promise<DeactivateWithKeepaliveResult>((resolve, reject) => {
          const cleanup = (): void => {
            clearTimeout(timeout);
            pendingDeactivateAllWithKeepalive = undefined;
          };

          const deactivateAfterTimeout = (): void => {
            try {
              deactivateAllModulets(contained.modulets, contained.scope)
                .then(() => {
                  cleanup();
                  return resolve(DeactivateWithKeepaliveResults.deactivated);
                })
                .catch(reject);
            } catch (error) {
              reject(error);
            }
          };
          let timeout = setTimeout(deactivateAfterTimeout, milliseconds);

          newPendingDeactivateAllWithKeepalive.cancelDueReactivate = () => {
            cleanup();
            resolve(DeactivateWithKeepaliveResults.reactivated);
          };

          newPendingDeactivateAllWithKeepalive.forceDeactivateImmediate =
            async () => {
              cleanup();
              await deactivateAllModulets(contained.modulets, contained.scope);
              resolve(DeactivateWithKeepaliveResults.deactivated);
            };

          newPendingDeactivateAllWithKeepalive.restartTimeout = async (
            newMilliseconds: number,
          ): Promise<DeactivateWithKeepaliveResult> => {
            clearTimeout(timeout);
            timeout = setTimeout(deactivateAfterTimeout, newMilliseconds);
            return newPendingDeactivateAllWithKeepalive.promise;
          };
        });

      return newPendingDeactivateAllWithKeepalive.promise;
    },

    whenAllActivated: async () => whenAllActivated(contained.modulets),
    whenAllDeactivated: async () => whenAllDeactivated(contained.modulets),

    getValue: <TExportName extends keyof TScopeOfAll>(
      exportName: TExportName,
    ): NonNullable<TScopeOfAll[TExportName]> => {
      return getScopeValue(scope, exportName);
    },
    getAllValues: (): TScopeOfAll => {
      return toScopeSnapshot(contained.scope);
    },

    getChild,
    removeChild,

    asContainerOf: <TCreateModules extends CreateModuletAny[]>(
      ...createModules: TCreateModules
    ): ModuletContainer<
      TExportsAll & ExportsOf<TCreateModules[number]>,
      TScopeOfAll & ExportsOf<TCreateModules[number]>
    > => {
      if (process.env.NODE_ENV === 'development') {
        for (const create of createModules) {
          const isContained =
            contained.modulets.has(
              create as ModuletKey<TImportsAll, TExportsAll>,
            ) ||
            someOf(
              contained.modulets.values(),
              (modulet) => modulet.create === create,
            );
          if (!isContained) {
            throw new TypeError(
              `Module container does not contain modulet factory: ${create.name}`,
            );
          }
        }
      }
      return moduletContainer as ModuletContainer<
        TExportsAll & ExportsOf<TCreateModules[number]>,
        TScopeOfAll & ExportsOf<TCreateModules[number]>
      >;
    },
  };

  return moduletContainer;
}

function someOf<T>(
  iterator: Iterator<T>,
  predicate: (value: T) => boolean,
): boolean {
  let done = true;
  do {
    const iteration = iterator.next();
    done = iteration.done ?? false;
    if (predicate(iteration.value)) {
      return true;
    }
  } while (!done);
  return false;
}

/**
 * Activates all modulets created by the passed function(s) in the passed
 * container.
 *
 * Await {@link ModuletContainer#whenAllActivated} to ensure that the exports
 * of any modulets with async activation have finished.
 *
 * @param container
 * @param createOrCreateMultiple
 */
// eslint-disable-next-line max-lines-per-function
export function activateAll<
  TCreateModuletOrMultiple extends
    | CreateModuletAny
    | CreateModuletAny[]
    | readonly CreateModuletAny[],
  TExports extends ModuletExportsBase,
  TScope extends TExports = TExports,
>(
  container: ModuletContainer<TExports, TScope>,
  createOrCreateMultiple: TCreateModuletOrMultiple,
): boolean {
  type Imports = ImportsOfAll<TCreateModuletOrMultiple>;
  type Exports = ExportsOfAll<TCreateModuletOrMultiple>;
  if (typeof createOrCreateMultiple === 'function') {
    const activated = container.activate<Imports, Exports>(
      createOrCreateMultiple as unknown as CreateModulet<Imports, Exports>,
    );
    return activated.isInitialActivation;
  }

  const createMultiple = createOrCreateMultiple as CreateModuletAny[];
  const length = createMultiple.length;
  if (length === 0) {
    throw new RangeError(
      'Expected at least one function to create a modulet instance.',
    );
  }
  let isInitialActivationForAll = false;
  for (let index = 0; index < length; index++) {
    const create = createMultiple[index] as CreateModulet<Imports, Exports>;
    const activated = container.activate(create);

    if (
      index > 0 &&
      isInitialActivationForAll !== activated.isInitialActivation
    ) {
      const previous = createMultiple[index - 1];
      throw new InvariantViolationError(
        'Expected all modulets to be activated at the same time, but' +
          `${String(create)}) is ${
            activated.isInitialActivation
              ? 'activated for the first time'
              : 'already activated'
          } while ${String(previous)} is not.`,
      );
    }
    isInitialActivationForAll = activated.isInitialActivation;
  }
  return isInitialActivationForAll;
}

/**
 * Creates a container that activates the modulets created by the passed
 * function(s) so that it's scope will contain the exports of those modulets.
 *
 * Await {@link ModuletContainer#whenAllActivated} to ensure that the exports
 * of any modulets with async activation have finished.
 *
 * @param createOrCreateMultiple One or multiple functions creating a modulet
 * instance. The modulets will be activated in order: if one modulet imports
 * the exports of another ensure that the exporting modulet comes first!
 */
export function createModuletContainerOfActivated<
  TCreateModuletOrMultiple extends
    | CreateModuletAny
    | CreateModuletAny[]
    | readonly CreateModuletAny[],
>(
  createOrCreateMultiple: TCreateModuletOrMultiple,
): ModuletContainer<ExportsOfAll<TCreateModuletOrMultiple>> {
  const container = createModuletContainer<
    ImportsOfAll<TCreateModuletOrMultiple>,
    ExportsOfAll<TCreateModuletOrMultiple>
  >();
  activateAll(container, createOrCreateMultiple);
  return container;
}
