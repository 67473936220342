/* eslint-disable @typescript-eslint/ban-types */

/**
 * Yields all prototypes.
 *
 * @param object
 */
export function* getPrototypesGenerator(
  object: object,
): Generator<object, void, void> {
  let prototype: object | null = object;
  while (
    (prototype = Object.getPrototypeOf(prototype) as object | null) != null
  ) {
    yield prototype;
  }
}

/**
 * Like {@link Object#getOwnPropertyNames} but walks up the prototype chain
 * to find all names, except for prototype exclusions
 *
 * @param object Object that contains the property.
 * @param excludedPrototypes e.g. use `[Object.prototype]`.
 */
export function getOwnOrPrototypePropertyNames(
  object: object,
  excludedPrototypes?: object[],
): string[] {
  const propertyNames = Object.getOwnPropertyNames(object);
  for (const prototype of getPrototypesGenerator(object)) {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (excludedPrototypes != null && excludedPrototypes.includes(prototype))
      continue;
    propertyNames.push(...Object.getOwnPropertyNames(prototype));
  }
  return propertyNames;
}

/**
 * Like {@link Object#getOwnPropertyDescriptor} but walks up the prototype chain
 * to find a descriptor.
 *
 * @param object Object that contains the property.
 * @param key Name of the property.
 */
export function getOwnOrPrototypePropertyDescriptor(
  object: object,
  key: PropertyKey,
): PropertyDescriptor | undefined {
  const ownDescriptor = Object.getOwnPropertyDescriptor(object, key);
  if (ownDescriptor) return ownDescriptor;

  for (const prototype of getPrototypesGenerator(object)) {
    const prototypeDescriptor = Object.getOwnPropertyDescriptor(prototype, key);
    if (prototypeDescriptor) {
      return prototypeDescriptor;
    }
  }
}

/**
 * Like {@link Object#getOwnPropertyDescriptors} but walks up the prototype chain
 * to aggregate descriptors that are not already defined lower in the chain.
 *
 * @param object Object that contains the properties itself or in its prototype.
 */
export function getOwnAndPrototypePropertyDescriptors<T extends object>(
  object: T,
): { [P in keyof T]: TypedPropertyDescriptor<T[P]> } & {
  [x: string]: PropertyDescriptor;
} {
  let descriptors = Object.getOwnPropertyDescriptors(object);

  for (const prototype of getPrototypesGenerator(object)) {
    const prototypeDescriptors = Object.getOwnPropertyDescriptors(prototype);
    Object.assign(prototypeDescriptors, descriptors);
    descriptors = prototypeDescriptors as {
      [P in keyof T]: TypedPropertyDescriptor<T[P]>;
    } & { [x: string]: PropertyDescriptor };
  }

  return descriptors;
}

/* eslint-enable @typescript-eslint/ban-types */
