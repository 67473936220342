import { getOwnAndPrototypePropertyDescriptors } from '../extras-javascript/object-prototype';
import type { ModuletExportsBase } from './modulet-imports-exports';

/**
 * @overview
 * Scope contains all exports of modulets activated in a container.
 *
 * The exports are assigned to the scope via their property descriptors.
 * This enables that modulet factories can use getters in their `exports`
 * for lazy instantiation: when assigning those to the scope the
 * getters will be copied instead of invoking them and copying their value.
 * The value will be produced by the getter the first time a dependent modulet
 * invokes it.
 *
 * A child container creates a child scope which inherits the exports of
 * its parent container. This is implemented by using the prototype chain:
 * therefore the scopes hierarchy is "live" meaning that an export
 * activated in a parent container becomes visible in the child container's
 * scope – even if the activation happens after the child container has
 * been created.
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export type ScopeBase = object;

/**
 * Creates an empty root scope.
 */
export function createEmptyScope<
  TScope extends ScopeBase = ScopeBase,
>(): TScope {
  return Object.create(null) as TScope;
}

/**
 * Creates a child scope that inherits the exported value of its parent scope
 * via the prototype chain.
 *
 * @param parentScope whose exported values are inherited.
 * @param exports Optional exports assigned to the created child scope.
 */
export function createChildScope<
  TScope extends ScopeBase,
  TExports extends ModuletExportsBase,
>(parentScope: TScope, exports: TExports): TScope & TExports;
/**
 *
 * Creates a child scope that inherits the exported value of its parent scope
 * via the prototype chain.
 *
 * @param parentScope whose exported values are inherited.
 */
export function createChildScope<TScope extends ScopeBase>(
  parentScope: TScope,
): TScope;
export function createChildScope<
  TScope extends ScopeBase,
  TExports extends ModuletExportsBase,
>(parentScope: TScope, exports?: TExports): TScope & TExports {
  return (
    exports != null
      ? Object.create(parentScope, Object.getOwnPropertyDescriptors(exports))
      : Object.create(parentScope)
  ) as TScope & TExports;
}

/**
 * Assigns exported values by copying all own property descriptors.
 *
 * Copying property descriptors instead of values ensures that getters
 * can be used for lazy instantiation.
 *
 * During development this warns if a export name is already present in
 * `target` which will overwrite the existing one.
 *
 * @param target Target scope.
 * @param exports Contains exports as own properties with the property name being the export name and value
 * being the export value. All contained own property descriptors will be assigned to `target`.
 */
export function assignExports<
  // eslint-disable-next-line @typescript-eslint/ban-types
  TTarget extends object,
  TExports extends ModuletExportsBase,
>(target: TTarget, exports: TExports): TTarget & TExports {
  const exportsProperties = Object.getOwnPropertyDescriptors(exports);
  if (process.env.NODE_ENV === 'development') {
    const overriddenExportNames = Object.keys(exportsProperties).filter(
      (propertyName: string) =>
        Object.prototype.hasOwnProperty.call(target, propertyName) &&
        (target[propertyName as keyof TTarget] as unknown) !==
          (exports[propertyName as keyof TExports] as unknown),
    );
    if (overriddenExportNames.length > 0) {
      console.warn(
        `Export${
          overriddenExportNames.length > 1 ? 's' : ''
        } ${overriddenExportNames.join(',')}`,
        'will overwrite existing export in',
        target,
      );
    }
  }
  return Object.defineProperties(target, exportsProperties) as TTarget &
    TExports;
}

export function assignExportsToScope<
  TScope extends ScopeBase,
  TExports extends ModuletExportsBase,
>(scope: TScope, exports: TExports): TScope & TExports {
  return assignExports(scope, exports);
}

export function deleteExports<
  // eslint-disable-next-line @typescript-eslint/ban-types
  TTarget extends object,
  TExports extends ModuletExportsBase,
>(
  target: TTarget,
  exports: TExports,
): {
  [TKey in keyof TTarget]: TKey extends keyof TExports
    ? undefined
    : TTarget[TKey];
} {
  const exportNames = Object.getOwnPropertyNames(exports) as (keyof TTarget)[];
  for (const exportName of exportNames) {
    delete target[exportName];
  }
  return target as {
    [TKey in keyof TTarget]: TKey extends keyof TExports
      ? undefined
      : TTarget[TKey];
  };
}

/**
 * Gets a value from scope by its export name. The value has be exported
 * by a modulet that has previously been activated in the container this
 * scope belongs to.
 *
 * @param scope
 * @param exportName
 *
 * @throws RangeError thrown when the exported name does not exist in the scope.
 */
export function getScopeValue<
  TScope extends ScopeBase,
  TExportName extends keyof TScope,
>(scope: TScope, exportName: TExportName): NonNullable<TScope[TExportName]> {
  const value = scope[exportName];
  if ((value as unknown) == null) {
    throw new RangeError(
      `No value for export "${String(
        exportName,
      )}" found in scope ${JSON.stringify(scope)}`,
    );
  }
  return value as NonNullable<TScope[TExportName]>;
}

/**
 * Maps to a readonly scope that throws if attempted to be mutated.
 *
 * In contrast to a snapshot this is still "live" so future exports
 * to the `scope` and its parent(s) are still reflected in the scope.
 *
 * Readonly scopes are exposed to modulet factories which should not
 * mutate the scope but may still benefit from live scopes, e.g. when
 * the factory uses a getter to lazily create an instance that accesses
 * an import from the scope which is not yet available when the factory
 * is invoked but available later when the getter is invoked.
 *
 * @param scope
 */
export function toReadonlyScope<TScope extends ScopeBase = ScopeBase>(
  scope: TScope,
): Readonly<TScope> {
  return Object.freeze(Object.create(scope) as TScope);
}

/**
 * Maps to a scope snapshot that contains the current exports of the
 * modulets activated in the respective container as enumerable properties.
 * Future exports assigned to the containers scope after the snapshot
 * is created will not be reflected in the snapshot.
 *
 * @param scope
 */
export function toScopeSnapshot<TScope extends ScopeBase = ScopeBase>(
  scope: TScope,
): TScope {
  return Object.create(
    null,
    getOwnAndPrototypePropertyDescriptors(scope),
  ) as TScope;
}
