import nameof from 'ts-nameof.macro';
import { asString } from '../../../../base/extras-typescript-asserts/type-string-asserts';

// disable: oidc 3rd party lib does not follow naming conventions
/* eslint-disable @typescript-eslint/naming-convention */
export const appAuthenticationClientCustomerRoleConfig = {
  authority: asString(
    process.env.REACT_APP_AUTH_AUTHORITY,
    nameof.full(process.env.REACT_APP_AUTH_AUTHORITY),
  ),

  client_id: asString(
    process.env.REACT_APP_AUTH_CLIENT_ID,
    nameof.full(process.env.REACT_APP_AUTH_CLIENT_ID),
  ),
  redirect_uri: asString(
    process.env.REACT_APP_AUTH_REDIRECT_URI,
    nameof.full(process.env.REACT_APP_AUTH_REDIRECT_URI),
  ),
  post_logout_redirect_uri: asString(
    process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
    nameof.full(process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI),
  ),
  silent_redirect_uri: asString(
    process.env.REACT_APP_AUTH_REDIRECT_URI,
    nameof.full(process.env.REACT_APP_AUTH_REDIRECT_URI),
  ),
  response_type: 'code',
  scope: asString(
    process.env.REACT_APP_AUTH_SCOPES,
    nameof.full(process.env.REACT_APP_AUTH_SCOPES),
  ),
  client_secret: asString(
    process.env.REACT_APP_AUTH_CLIENT_SECRET,
    nameof.full(process.env.REACT_APP_AUTH_CLIENT_SECRET),
  ),

  metadata: {
    issuer: asString(
      process.env.REACT_APP_AUTH_ISSUER,
      nameof.full(process.env.REACT_APP_AUTH_ISSUER),
    ),
    userinfo_endpoint: asString(
      process.env.REACT_APP_AUTH_END_USER,
      nameof.full(process.env.REACT_APP_AUTH_END_USER),
    ),
    authorization_endpoint: asString(
      process.env.REACT_APP_AUTH_AUTHORIZATION,
      nameof.full(process.env.REACT_APP_AUTH_AUTHORIZATION),
    ),
    token_endpoint: asString(
      process.env.REACT_APP_AUTH_TOKEN,
      nameof.full(process.env.REACT_APP_AUTH_TOKEN),
    ),
    end_session_endpoint: asString(
      process.env.REACT_APP_AUTH_END_SESSION,
      nameof.full(process.env.REACT_APP_AUTH_END_SESSION),
    ),
  },
};
/* eslint-enable @typescript-eslint/naming-convention */
