import type { ModuletInstance } from '../../../../base/modulet/modulet';
import type { AppServiceWorker } from './app-service-worker';
import { createAppServiceWorker } from './app-service-worker';

export type AppServiceWorkerModuletExports = {
  serviceWorker: AppServiceWorker;
};

/**
 * Modulet to expose the {@link AppServiceWorker} to React component(s)
 * for notifying and performing updates.
 *
 * No (async) `activate()` is used for registering the services worker because
 * we don't need to wait for the registration to complete – a service
 * worker is a progressive enhancement so the rest of the app does not rely
 * on it. The async nature of the registration is reflected in
 * {@link AppServiceWorker#registration} being a promise so only code that
 * really needs to access it has to deal with asynchronicity.
 */
export function createAppServiceWorkerModulet(): ModuletInstance<AppServiceWorkerModuletExports> {
  const serviceWorker = createAppServiceWorker();

  return { exports: { serviceWorker } };
}
