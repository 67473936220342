import type { ReportHandler } from 'web-vitals';

export async function reportWebVitals(
  onPerfEntry?: ReportHandler,
): Promise<void> {
  if (typeof onPerfEntry !== 'function') return;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import(
    /* webpackChunkName: "web-vitals" */ 'web-vitals'
  );
  getCLS(onPerfEntry);
  getFID(onPerfEntry);
  getFCP(onPerfEntry);
  getLCP(onPerfEntry);
  getTTFB(onPerfEntry);
}
