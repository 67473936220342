import type { ValuesType } from 'utility-types';
import type { TypeGuardPredicate } from './type-predicate-asserts';
import {
  assertType,
  assertTypeUnion,
  isTypeUnion,
} from './type-predicate-asserts';

export function isErrorObject(value: unknown): value is Error {
  return (
    typeof value === 'object' &&
    value != null &&
    Object.prototype.toString.call(value) === '[object Error]'
  );
}

export function isError<TError extends Error = Error>(
  value: unknown,
  isErrorType?: (value: Error) => value is TError,
): value is TError {
  return isErrorObject(value) && (isErrorType == null || isErrorType(value));
}

export function assertError<TError extends Error = Error>(
  value: unknown,
  isErrorType?: (value: Error) => value is TError,
): asserts value is TError {
  if (!isError(value, isErrorType)) {
    throw new TypeError('Expected value to be an "Error".');
  }
}

export function asError<TError extends Error = Error>(
  value: unknown,
  isErrorType?: (value: Error) => value is TError,
): TError {
  assertError(value, isErrorType);
  return value;
}

export type ErrorConstructorAnyArgs<T extends Error = Error> = new (
  ...args: never
) => T;

export function isErrorOf<TError extends Error>(
  value: unknown,
  ErrorConstructor: ErrorConstructorAnyArgs<TError>,
): value is TError {
  const isObject = typeof value === 'object' && value != null;
  const isInstanceOfError = isObject && value instanceof ErrorConstructor;
  return (
    isInstanceOfError ||
    (isErrorObject(value) &&
      (value.name === ErrorConstructor.name ||
        value.constructor.name === ErrorConstructor.name))
  );
}

export function assertErrorOf<TError extends Error>(
  value: unknown,
  ErrorConstructor: ErrorConstructorAnyArgs<TError>,
): asserts value is TError {
  return assertType(
    value,
    (_value: unknown): _value is TError => isErrorOf(value, ErrorConstructor),
    'error',
    ErrorConstructor.name,
  );
}

/**
 * All instance types (return type of a constructor) from an array of error constructors.
 *
 * E.g. for an `[RangeError, TypeError]` the type is `RangeError | TypeError`.
 */
export type ErrorInstanceTypes<
  TErrorConstructors extends ErrorConstructorAnyArgs<TError>[],
  TError extends Error = Error,
> = ValuesType<{
  [TIndex in keyof TErrorConstructors]: TErrorConstructors[TIndex] extends new (
    ...args: never
  ) => infer R
    ? R
    : never;
}>;

function createErrorUnionPredicates<
  TError extends Error,
  TErrorConstructors extends ErrorConstructorAnyArgs<TError>[],
>(
  value: unknown,
  ErrorConstructors: TErrorConstructors,
): TypeGuardPredicate<TError>[] {
  return ErrorConstructors.map(
    (ErrorConstructor) =>
      (_value: unknown): _value is TError =>
        isErrorOf(value, ErrorConstructor),
  );
}

export function isErrorUnionOf<
  TError extends Error,
  TErrorConstructors extends ErrorConstructorAnyArgs<TError>[],
>(
  value: unknown,
  ErrorConstructors: TErrorConstructors,
): value is ErrorInstanceTypes<TErrorConstructors, TError> {
  return isTypeUnion(
    value,
    createErrorUnionPredicates(value, ErrorConstructors),
  );
}

export function assertErrorUnionOf<
  TError extends Error,
  TErrorConstructors extends ErrorConstructorAnyArgs<TError>[],
>(
  value: unknown,
  ErrorConstructors: TErrorConstructors,
): asserts value is ErrorInstanceTypes<TErrorConstructors, TError> {
  const typePredicates = createErrorUnionPredicates(value, ErrorConstructors);
  const typeNames = ErrorConstructors.map(
    (ErrorConstructor) => ErrorConstructor.name,
  );
  return assertTypeUnion(value, typePredicates, 'error', typeNames);
}
