import nameof from 'ts-nameof.macro';
import type { ThisParameter } from '../extras-typescript/function';
import type { IsVoid } from '../extras-typescript/nothing-types';
import type {
  AddHandlerOptions,
  EventHandlersDelegate,
  Handler,
  HandlerArgsAny,
  HandlerReturn,
  RemoveHandler,
} from './event-handlers';

// use `void` for no `this` as TypeScript uses that too when calling function without `this`
/* eslint-disable @typescript-eslint/no-invalid-void-type */

type AddHandlerWithStateOptionsShape<TThis = void> = AddHandlerOptions & {
  shouldInitialize?: boolean;
} & {
  thisArg?: TThis;
};

// `object` is a noop/identity for `&` intersection
/* eslint-disable @typescript-eslint/ban-types */
type AddHandlerWithStateInitOptions<
  TShouldInitialize extends boolean,
  TThis,
> = AddHandlerOptions & {
  shouldInitialize: TShouldInitialize;
} & (IsVoid<TThis> extends true
    ? object
    : {
        thisArg: TThis;
      });
/* eslint-enable @typescript-eslint/ban-types */

/**
 * Sets all indices of a arguments tuple to undefined
 * while preserving array methods.
 */
type ArgsUndefined<TArgs extends HandlerArgsAny> = {
  [TKey in keyof TArgs]: TKey extends `${number}` ? undefined : TArgs[TKey];
};

type EventHandlersDelegateWithState<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TInvocationReturn extends HandlerReturn,
  TInitialArgs extends TArgs | Readonly<TArgs> | undefined,
  TThis = void,
> = Omit<
  EventHandlersDelegate<TArgs, TReturn, TInvocationReturn, TThis>,
  'add'
> & {
  add: {
    // if `shouldInitialize` is used and no initial args are defined a handler is initially passed the `undefined` initial state
    <TShouldInitialize extends boolean>(
      handler: Handler<
        TShouldInitialize extends true
          ? TInitialArgs extends undefined
            ? TArgs | ArgsUndefined<TArgs>
            : TArgs
          : TArgs,
        TReturn,
        TThis
      >,
      options: AddHandlerWithStateInitOptions<TShouldInitialize, TThis>,
    ): RemoveHandler;
    // without `shouldInitialize` a handler is never passed the `undefined` initial state
    (
      handler: Handler<TArgs, TReturn, TThis>,
      options?: AddHandlerOptions,
    ): RemoveHandler;
  };
  getAll: () => TInitialArgs extends undefined ? TArgs | undefined : TArgs;
  get: () => TInitialArgs extends undefined ? TArgs[0] | undefined : TArgs[0];
  /**
   * Sets the internal state and invokes handlers if the arguments
   * differ from their last invocation / initial values.
   *
   * This is the same as {@link EventHandlersDelegateWithState#invoke} except:
   * - no promise is returned in case of scheduled invocations (use `invoke`
   *   if you need to wait for async handlers execution)
   * - handlers are not invoked if arguments are considered equal. By default
   *   [SameValueZero]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality}
   *   is used to determine equality, but custom function(s) can
   *   be specified. If `null` is specified equality checks are turned off and
   *   handlers regardless if the value changed or not
   *   (same behavior as `invoke()`).
   */
  set: (
    ...args: TInitialArgs extends undefined
      ? TArgs | ArgsUndefined<TArgs>
      : TArgs
  ) => void;
  reset: () => void;
};

export type EventHandlersWithState<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TInitialArgs extends TArgs | Readonly<TArgs> | undefined,
  TThis = void,
> = Pick<
  EventHandlersDelegateWithState<TArgs, TReturn, never, TInitialArgs, TThis>,
  'add' | 'size' | 'getAll' | 'get'
>;

export type EventHandlersWithStateOf<
  THandler extends Handler<HandlerArgsAny, HandlerReturn, never>,
  TInitialArgs extends Parameters<THandler> | undefined,
> = EventHandlersWithState<
  Parameters<THandler>,
  ReturnType<THandler>,
  TInitialArgs,
  ThisParameter<THandler>
>;

const addHandlerWithStateOptionsDefault: AddHandlerWithStateInitOptions<
  false,
  void
> = {
  shouldInitialize: false,
};

type IsEqual<T> = (value: T, other: T) => boolean;

/**
 * Checks for equality using [SameValueZero]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality}.
 *
 * This is the same as `===` except for considering `NaN` equal.
 *
 * @param value
 * @param other
 */
function isSameValueZero(value: unknown, other: unknown): boolean {
  // `NaN` is the only value different to itself so `NaN === NaN` is `false`
  // eslint-disable-next-line no-self-compare
  return value === other || (value !== value && other !== other);
}

/**
 * Either a single equality function to use for all arguments,
 * or an array of equality functions where the index corresponds to
 * the index of the argument or `null` to don't use equality checks.
 */
type IsEqualArgs<TArgs extends HandlerArgsAny> =
  | IsEqual<TArgs[number]>
  | { [TIndex in keyof TArgs]: IsEqual<TArgs[TIndex]> }
  | Readonly<{ [TIndex in keyof TArgs]: IsEqual<TArgs[TIndex]> }>
  | null;

function isArgumentEqual<T>(
  isEqual: IsEqual<T>,
  value: T,
  other: T | undefined,
): boolean {
  if (value === other) return true;
  if (other == null && value != null) {
    return false;
  }
  return isEqual(value, other as T);
}
function areArgumentsEqual<
  TArgs extends HandlerArgsAny,
  TInitialArgs extends TArgs | Readonly<TArgs>,
>(
  isEqual: IsEqualArgs<TArgs>,
  args: TArgs,
  existing: TArgs | TInitialArgs | undefined,
): boolean {
  if (isEqual == null) return false;
  if (typeof isEqual === 'function') {
    return args.every((value, index) => {
      return isArgumentEqual(isEqual, value, existing?.[index]);
    });
  }
  return args.every((value, index) => {
    const isEqualAtIndex = isEqual[index];
    if (isEqualAtIndex == null) {
      throw new TypeError(
        `Missing equality function to compare arguments at index ${index}.`,
      );
    }
    return isArgumentEqual(isEqualAtIndex, value, existing?.[index]);
  });
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop(): void {}

/*
overload to type optional initial args cases:
1. no initial args => `get()` can return undefined
2. initial args => `get()` is always defined
*/
export function withState<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TInvocationReturn extends HandlerReturn,
  TThis = void,
>(
  eventHandlers: EventHandlersDelegate<
    TArgs,
    TReturn,
    TInvocationReturn,
    TThis
  >,
): EventHandlersDelegateWithState<
  TArgs,
  TReturn,
  TInvocationReturn,
  undefined,
  TThis
>;
export function withState<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TInvocationReturn extends HandlerReturn,
  TInitialArgs extends TArgs | Readonly<TArgs>,
  TThis = void,
>(
  eventHandlers: EventHandlersDelegate<
    TArgs,
    TReturn,
    TInvocationReturn,
    TThis
  >,
  initialArgs: TInitialArgs,
  isEqual?: IsEqualArgs<TArgs>,
): EventHandlersDelegateWithState<
  TArgs,
  TReturn,
  TInvocationReturn,
  TInitialArgs,
  TThis
>;
/**
 * Decorates event handlers with keeping the state of the current arguments passed to
 * the handlers.
 *
 * This is used to initialize an observer with the current state of the observable
 * when the observer `add()`s a handler. This way the state of both is always in sync
 * even if the observer "missed" an event bore it added a handler.
 *
 * @param eventHandlers to decorate with state.
 * @param initialArgs state to use before the first time any handler is invoked.
 * @param isEqual used to test if the [set]{@link EventHandlersDelegateWithState#set}
 * state equals the current in which case no handlers are invoked. Defaults to testing
 * for [SameValueZero]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality}
 * equality. Use `null` to disable equality checks.
 * Note that {@link EventHandlersDelegateWithState#invoke} always invokes handlers
 * regardless of equality.
 * @return Event handlers decorated with state.
 */
// function serves as scope so needs more lines
// eslint-disable-next-line max-lines-per-function
export function withState<
  TArgs extends HandlerArgsAny,
  TReturn extends HandlerReturn,
  TInvocationReturn extends HandlerReturn,
  TInitialArgs extends TArgs | Readonly<TArgs>,
  TThis = void,
>(
  eventHandlers: EventHandlersDelegate<
    TArgs,
    TReturn,
    TInvocationReturn,
    TThis
  >,
  initialArgs?: TInitialArgs,
  isEqual: IsEqualArgs<TArgs> = isSameValueZero,
): EventHandlersDelegateWithState<
  TArgs,
  TReturn,
  TInvocationReturn,
  TInitialArgs,
  TThis
> {
  if (eventHandlers.size > 0) {
    throw new RangeError(
      `Event handlers must not have any handlers when decorating ${nameof(
        withState,
      )}`,
    );
  }

  let argsState: TArgs | undefined = initialArgs;

  function getAll(): TArgs | undefined {
    return argsState;
  }
  function get(): TArgs[0] | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return argsState?.[0] as TArgs[0] | undefined;
  }

  const invokeOriginal = (
    eventHandlers as unknown as EventHandlersDelegate<
      TArgs,
      TReturn,
      TInvocationReturn,
      TThis
    >
  ).invoke;
  function invoke(this: TThis, ...args: TArgs): TInvocationReturn {
    argsState = args;
    return invokeOriginal.apply(this, args);
  }

  function set(...args: TArgs): void {
    if (isEqual != null && areArgumentsEqual(isEqual, args, argsState)) {
      return;
    }
    type InvokeWithoutThis = (this: void, ..._args: TArgs) => TInvocationReturn;
    void (invoke as InvokeWithoutThis)(...args);
  }
  function reset(): void {
    if (Array.isArray(initialArgs)) {
      set(...(initialArgs as TArgs));
    } else {
      // initial state can only be array of arguments or `undefined`
      // @ts-expect-error TS2345: Argument of type '[undefined]' is not assignable to parameter of type 'TArgs'
      set(undefined);
    }
  }

  const addOriginal = eventHandlers.add;
  function add(
    handler: Handler<TArgs, TReturn, TThis>,
    options: AddHandlerWithStateOptionsShape<TThis> = addHandlerWithStateOptionsDefault as AddHandlerWithStateOptionsShape<TThis>,
  ): RemoveHandler {
    const { shouldInitialize, thisArg, isOnce } = options;

    if (isOnce && shouldInitialize && argsState !== undefined) {
      void handler.apply(thisArg as TThis, argsState);
      return noop;
    }

    const removeHandler = addOriginal(handler, { isOnce });
    if (shouldInitialize && argsState !== undefined) {
      void handler.apply(thisArg as TThis, argsState);
    }

    return removeHandler;
  }

  return Object.assign(Object.create(eventHandlers), {
    add,
    invoke,
    getAll,
    get,
    set,
    reset,
  }) as EventHandlersDelegateWithState<
    TArgs,
    TReturn,
    TInvocationReturn,
    TInitialArgs,
    TThis
  >;
}

/* eslint-enable @typescript-eslint/no-invalid-void-type */
