const roles = ['service', 'customer'] as const;

export type AuthenticationRole = typeof roles[number];

export function isAuthenticationRole(
  value: unknown,
): value is AuthenticationRole {
  return (
    typeof value === 'string' && (roles as readonly string[]).includes(value)
  );
}

function getAuthenticationRoleOrDefault(
  role: AuthenticationRole | undefined,
): AuthenticationRole {
  return role ?? 'customer';
}

export function asAuthenticationRoleOrDefault(
  value: unknown,
): AuthenticationRole {
  const roleMaybe = isAuthenticationRole(value) ? value : undefined;
  return getAuthenticationRoleOrDefault(roleMaybe);
}
