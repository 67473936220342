import { assertType } from './type-predicate-asserts';

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function assertString(
  value: unknown,
  valueName: string,
): asserts value is string {
  assertType(value, isString, valueName, 'string');
}

export function asString(value: unknown, valueName: string): string {
  assertString(value, valueName);
  return value;
}

export function isStringLiteral<TStringLiteral extends string>(
  value: unknown,
  literal: TStringLiteral,
): value is TStringLiteral {
  return isString(value) && value === literal;
}

export function assertStringLiteral<TStringLiteral extends string>(
  value: unknown,
  literal: TStringLiteral,
  valueName: string,
): asserts value is TStringLiteral {
  const isThatLiteral = (_value: unknown): _value is TStringLiteral =>
    isStringLiteral(_value, literal);
  assertType(value, isThatLiteral, valueName, literal);
}

export function asStringLiteral<TStringLiteral extends string>(
  value: unknown,
  literal: TStringLiteral,
  valueName: string,
): string {
  assertStringLiteral(value, literal, valueName);
  return value;
}
