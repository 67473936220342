/* istanbul ignore file – untested entry code */
import { createEventHandlersSync } from '../../../../base/event/event-handlers';
import type { EventHandlersWithStateOf } from '../../../../base/event/event-handlers-state';
import { withState } from '../../../../base/event/event-handlers-state';
import type { ServiceWorkerRegistrationOptions } from './app-service-worker-registration';
import type {
  OnServiceWorkerUpdateAvailable,
  ServiceWorkerUpdateAvailable,
} from './service-worker-update';

type AppServiceWorkerSetAutoUpdate = (shouldAutoUpdate: boolean) => void;

export type AppServiceWorker = {
  onUpdateAvailable: EventHandlersWithStateOf<
    OnServiceWorkerUpdateAvailable,
    undefined
  >;
  setAutoUpdate: AppServiceWorkerSetAutoUpdate;
  registration: Promise<ServiceWorkerRegistration | undefined>;
};

/**
 * Create the {@link AppServiceWorker} to notify React component(s)
 * about service worker updates and perform those.
 *
 * The service worker `registration` is loaded lazily and this is
 * deliberately not an `async` function to `await` the lazy creation
 * and `registration`:
 * we don't need to wait for the registration to complete – a service
 * worker is a progressive enhancement so the rest of the app does not rely
 * on it. The async nature of the registration is reflected in
 * {@link AppServiceWorker#registration} being a promise so only code that
 * really needs to access it has to deal with asynchronicity.
 */
export function createAppServiceWorker(): AppServiceWorker {
  // keep handler state for `useSubscription`
  const onUpdateAvailable = withState(
    createEventHandlersSync<OnServiceWorkerUpdateAvailable>(),
  );
  const serviceWorker: AppServiceWorker = {
    onUpdateAvailable,
    setAutoUpdate: createServiceWorkerAutoUpdate(onUpdateAvailable),
    registration: registerServiceWorkerLazyLoaded({
      onUpdateAvailable: (update) => {
        const updateWithUnsetState: ServiceWorkerUpdateAvailable = {
          ...update,
          performUpdateAndRestart: async (...args) => {
            await update.performUpdateAndRestart(...args);
            onUpdateAvailable.set(undefined);
          },
          dismissUpdate: () => {
            update.dismissUpdate();
            onUpdateAvailable.set(undefined);
          },
        };

        onUpdateAvailable.set(updateWithUnsetState);
      },
    }),
  };

  return serviceWorker;
}

function createServiceWorkerAutoUpdate(
  onUpdateAvailable: AppServiceWorker['onUpdateAvailable'],
): AppServiceWorkerSetAutoUpdate {
  let shouldUpdate = false;
  let serviceWorkerUpdate: ServiceWorkerUpdateAvailable | undefined;
  /*
  no need to remove listener as there is only a singleton service worker
  bound to the app lifecycle: service worker (and listener) exists as long
  as the app exists, if the app unloads the listener is garbage collected
  */
  onUpdateAvailable.add((update) => {
    serviceWorkerUpdate = update;
    maybePerformAutoUpdateAndRestart();
  });

  function maybePerformAutoUpdateAndRestart(): void {
    if (!shouldUpdate) return;
    // TODO IMOWAPP-321 log error to update service worker
    void serviceWorkerUpdate?.performUpdateAndRestart();
  }

  function enableAutoUpdate(): void {
    shouldUpdate = true;
    maybePerformAutoUpdateAndRestart();
  }

  function disableAutoUpdate(): void {
    shouldUpdate = false;
  }

  return function setAutoUpdate(shouldAutoUpdate: boolean): void {
    if (shouldAutoUpdate) enableAutoUpdate();
    else disableAutoUpdate();
  };
}

/**
 * Loads service worker registration lazily (to [reduce size of the initially
 * loaded main bundle]{@link https://developers.google.com/web/tools/workbox/modules/workbox-window#loading_workbox_with_javascript_bundlers})
 * and registers the service worker.
 *
 * @see https://cra.link/PWA
 */
async function registerServiceWorkerLazyLoaded(
  options?: ServiceWorkerRegistrationOptions,
): Promise<ServiceWorkerRegistration | undefined> {
  if (!('serviceWorker' in navigator)) return undefined;

  const { registerServiceWorker } = await import(
    /* webpackChunkName: "app-service-worker-registration" */
    './app-service-worker-registration'
  );

  return registerServiceWorker(options);
}
