import nameof from 'ts-nameof.macro';

export class AppAuthenticationClientError extends Error {
  name = nameof(AppAuthenticationClientError);

  constructor(message: string, public cause?: unknown) {
    super(message);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, AppAuthenticationClientError);
    }
  }

  static fromCause(error: Error, message = ''): AppAuthenticationClientError {
    const joinedMessage = [message, error.message].join(': ');
    return new AppAuthenticationClientError(joinedMessage, error);
  }
}

export class AppAuthenticationClientRefreshError extends AppAuthenticationClientError {
  name = nameof(AppAuthenticationClientRefreshError);

  constructor(message: string, public cause?: unknown) {
    super(message, cause);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, AppAuthenticationClientRefreshError);
    }
  }

  static fromCause(
    error: Error,
    message = '',
  ): AppAuthenticationClientRefreshError {
    const joinedMessage = [message, error.message].join(': ');
    return new AppAuthenticationClientRefreshError(joinedMessage, error);
  }
}
