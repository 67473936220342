type PromiseStatus = 'pending' | 'resolved' | 'rejected';

/**
 * Used for non-initialized values instead of `undefined` / `null`
 * as those could be assigned from user code in which case we
 * could not distinguish between `undefined` being set by user code
 * or being un-initialized (not having been set yet).
 */
const None: unique symbol = Symbol('None');
type None = typeof None;

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type PromiseSettlable<TValue = void, TError = Error> = {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  resolve: [void] extends [TValue] ? () => void : (value: TValue) => void;
  reject: (error: TError) => void;
  readonly promise: Promise<TValue>;
  readonly status: PromiseStatus;
};

function isSettled(status: PromiseStatus | undefined): boolean {
  return status === 'resolved' || status === 'rejected';
}

function assertNotSettledWithDifferentResult(
  status: PromiseStatus | undefined,
  value: unknown,
  previousResult: unknown,
): void {
  if (isSettled(status) && value !== previousResult) {
    throw new Error(`Promise already ${status}`);
  }
}

/**
 * Creates a promise that can be settled by resolving/rejecting
 * via methods and allows to inspect its status.
 */
export function createPromiseSettlable<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  TValue = void,
  TError = Error,
>(): PromiseSettlable<TValue, TError> {
  let status: PromiseStatus = 'pending';
  let resolve: (value: TValue) => void;
  let reject: (error: TError) => void;
  let value: TValue | None = None;
  let error: TError | None = None;

  const promise: Promise<TValue> = new Promise((_resolve, _reject) => {
    resolve = (_value: TValue): void => {
      assertNotSettledWithDifferentResult(status, _value, value);
      status = 'resolved';
      value = _value;
      _resolve(_value);
    };
    reject = (_error: TError): void => {
      assertNotSettledWithDifferentResult(status, _error, error);
      status = 'rejected';
      error = _error;
      _reject(_error);
    };
  });
  return {
    // @ts-expect-error `TS2454: Variable is used before being assigned` – promise executor is sync => variable will be defined
    resolve,
    // @ts-expect-error `TS2454: Variable is used before being assigned` – promise executor is sync => variable will be defined
    reject,
    promise,
    get status(): PromiseStatus {
      return status;
    },
  };
}
