import type { ModuletInstance } from '../../base/modulet/modulet';
import { createAppAuthenticationRoleBasedClient } from './service/app-authentication-role-based-client/app-authentication-role-based-client';
import type { AppAuthenticationRoleBasedClient } from './service/app-authentication-role-based-client/app-authentication-role-based-client';

export type AppAuthenticationModuletExports = {
  authenticationClient: AppAuthenticationRoleBasedClient;
};

export function createAppAuthenticationModulet(): ModuletInstance<AppAuthenticationModuletExports> {
  const authenticationClient = createAppAuthenticationRoleBasedClient();

  async function activate(): Promise<() => void> {
    await authenticationClient.load();

    return function deactivate(): void {
      authenticationClient.dispose();
    };
  }

  return {
    exports: {
      authenticationClient,
    },
    activate,
  };
}
