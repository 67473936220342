import { addResourceHint } from '../../../../base/extras-browser/resource-hints';

/**
 * Add resource hint for pre-connecting to iMOW API server.
 *
 * Preconnect performs DNS lookup + TCP handshake + establishing
 * TLS tunnel. Doing this in advance speeds up fetching data
 * from the API for the initial render.
 */
export function addImowApiResourceHint(): void {
  addResourceHint({
    type: 'preconnect',
    href: process.env.REACT_APP_IMOW_API_BASE_URL as string,
    crossorigin: 'anonymous',
  });
}
