import nameof from 'ts-nameof.macro';
import { asString } from '../../../../base/extras-typescript-asserts/type-string-asserts';

// disable: oidc 3rd party lib does not follow naming conventions
/* eslint-disable @typescript-eslint/naming-convention */
export const appAuthenticationClientServiceRoleConfig = {
  authority: asString(
    process.env.REACT_APP_AUTH_AUTHORITY_SERVICE,
    nameof.full(process.env.REACT_APP_AUTH_AUTHORITY_SERVICE),
  ),

  client_id: asString(
    process.env.REACT_APP_AUTH_CLIENT_ID,
    nameof.full(process.env.REACT_APP_AUTH_CLIENT_ID),
  ),
  redirect_uri: asString(
    process.env.REACT_APP_AUTH_REDIRECT_URI_SERVICE,
    nameof.full(process.env.REACT_APP_AUTH_REDIRECT_URI_SERVICE),
  ),
  post_logout_redirect_uri: asString(
    process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
    nameof.full(process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI),
  ),
  silent_redirect_uri: asString(
    process.env.REACT_APP_AUTH_REDIRECT_URI_SERVICE,
    nameof.full(process.env.REACT_APP_AUTH_REDIRECT_URI_SERVICE),
  ),
  response_type: 'code',
  scope: asString(
    process.env.REACT_APP_AUTH_SCOPES,
    nameof.full(process.env.REACT_APP_AUTH_SCOPES),
  ),
  client_secret: asString(
    process.env.REACT_APP_AUTH_CLIENT_SECRET,
    nameof.full(process.env.REACT_APP_AUTH_CLIENT_SECRET),
  ),

  metadata: {
    issuer: asString(
      process.env.REACT_APP_AUTH_ISSUER_SERVICE,
      nameof.full(process.env.REACT_APP_AUTH_ISSUER_SERVICE),
    ),
    userinfo_endpoint: asString(
      process.env.REACT_APP_AUTH_END_USER_SERVICE,
      nameof.full(process.env.REACT_APP_AUTH_END_USER_SERVICE),
    ),
    authorization_endpoint: asString(
      process.env.REACT_APP_AUTH_AUTHORIZATION_SERVICE,
      nameof.full(process.env.REACT_APP_AUTH_AUTHORIZATION_SERVICE),
    ),
    token_endpoint: asString(
      process.env.REACT_APP_AUTH_TOKEN_SERVICE,
      nameof.full(process.env.REACT_APP_AUTH_TOKEN_SERVICE),
    ),
    end_session_endpoint: asString(
      process.env.REACT_APP_AUTH_END_SESSION_SERVICE,
      nameof.full(process.env.REACT_APP_AUTH_END_SESSION_SERVICE),
    ),
  },
};
/* eslint-enable @typescript-eslint/naming-convention */
