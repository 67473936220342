import type { AuthenticationRole } from './app-authentication-role';
import { asAuthenticationRoleOrDefault } from './app-authentication-role';

/**
 * Name of {@link AuthenticationRole} parameter referenced in URL
 * as `application/x-www-form-urlencoded` encoded parameters,
 * like `role=service`.
 */
const authenticationRoleParameterName = 'role' as const;

function getAuthenticationRoleFromUrlSearchParameters(
  urlSearchParameters: URLSearchParams,
): AuthenticationRole {
  return asAuthenticationRoleOrDefault(
    urlSearchParameters.get(authenticationRoleParameterName),
  );
}

function setAuthenticationRoleToSearchParameters(
  searchParameters: URLSearchParams,
  role: AuthenticationRole,
): void {
  if (role === 'customer') {
    searchParameters.delete(authenticationRoleParameterName);
  } else {
    searchParameters.set(authenticationRoleParameterName, role);
  }
}

export function setAuthenticationRoleToSearch(
  search: string | undefined,
  role: AuthenticationRole,
): '' | `?${string}` {
  const searchParameters = new URLSearchParams(search);
  setAuthenticationRoleToSearchParameters(searchParameters, role);
  const parametersString = searchParameters.toString();
  return parametersString !== '' ? `?${parametersString}` : '';
}

/**
 * Adds the [parameter]{@link authenticationRoleParameterName} to the url.
 *
 * Throws TypeError, if input is not a valid url.
 *
 * @param url
 * @param role
 */
export function setAuthenticationRoleToUrl(
  url: string,
  role: AuthenticationRole,
): string {
  const newUrl = new URL(url);
  setAuthenticationRoleToSearchParameters(newUrl.searchParams, role);
  return newUrl.href;
}

/**
 * Adds the [parameter]{@link authenticationRoleParameterName} to the url relative to origin.
 *
 * Throws TypeError, if input is not a valid url.
 *
 * @param url
 * @param role
 */

export function setAuthenticationRoleToUrlRelativeToOrigin(
  url: string,
  role: AuthenticationRole,
): string {
  if (!url.startsWith('/')) {
    throw new TypeError(
      `Expected URL relative to origin starting with '/' for the path: ${url}`,
    );
  }

  // `URL` can only parse complete URLs: use dummy so we can reuse URL#toString()
  const dummyUrl = 'https://url.com';
  const newUrl = new URL(dummyUrl.concat(url));
  setAuthenticationRoleToSearchParameters(newUrl.searchParams, role);
  return toUrlRelativeToOriginString(newUrl);
}

/**
 * Converts to an origin relative URL `/path?query#hash`.
 *
 * Can be used for client side routing via {@link history#pushState}.
 *
 * @param url
 */
function toUrlRelativeToOriginString(url: URL): string {
  return `${url.pathname}${url.search}${url.hash}`;
}

/**
 * Determines the {@link AuthenticationRole} from the URL and removes
 * the [parameter]{@link authenticationRoleParameterName} by modifying the
 * current URL in browser {@link history}.
 *
 * @param window
 */
export function getAuthenticationRoleFromUrl(
  window: Window,
): AuthenticationRole {
  return getAuthenticationRoleFromUrlSearchParameters(
    new URLSearchParams(window.location.search),
  );
}
