import { addResourceHint } from '../../../base/extras-browser/resource-hints';

const APP_WELCOME_PAGE_IMAGE_PATH_BASE = `${process.env.PUBLIC_URL}/app-welcome-page`;

export const appWelcomePageImageAttributes = {
  src: `${APP_WELCOME_PAGE_IMAGE_PATH_BASE}/welcome-page-background-1920.jpg`,
  srcSet: `        
    ${APP_WELCOME_PAGE_IMAGE_PATH_BASE}/welcome-page-background-600.jpg 600w,
    ${APP_WELCOME_PAGE_IMAGE_PATH_BASE}/welcome-page-background-1280.jpg 1280w,
    ${APP_WELCOME_PAGE_IMAGE_PATH_BASE}/welcome-page-background-1920.jpg 1920w
  `,
  sizes: `
    (max-width: 300px) 600px,
    (max-width: 680px) 1280px,
    1920px
  `,
};

/**
 * Preload the responsive images of the welcome page.
 */
export function addImowWelcomePageResourceHints(): void {
  addResourceHint({
    type: 'preload',
    href: appWelcomePageImageAttributes.src,
    as: 'image',
    imageSrcSet:
      `${process.env.PUBLIC_URL}/app-welcome-page/welcome-page-background-600.jpg 600w, ` +
      `${process.env.PUBLIC_URL}/app-welcome-page/welcome-page-background-1280.jpg 1280w, ` +
      `${process.env.PUBLIC_URL}/app-welcome-page/welcome-page-background-1920.jpg 1920w`,
    imageSizes: '(max-width: 300px) 600px, (max-width: 680px) 1280px, 1920px',
  });
}
