export class InvariantViolationError extends Error {
  name = 'InvariantViolationError';

  constructor(message: string, public cause?: unknown) {
    super(message);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, InvariantViolationError);
    }
  }
}
