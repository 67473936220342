import { render as renderReactDom } from 'react-dom';
import type { AppInitModuletExports } from './app/service/app-modulet/app-init-modulet';
import { createAppInitRootModuletContainer } from './app/service/app-modulet/app-init-modulet';
import { addImowWelcomePageResourceHints } from './app/ui/app-welcome-page/app-welcome-page-resources';
import { canUseDom } from './base/extras-browser/dom-usable';
import type { ModuletContainer } from './base/modulet/modulet-container';
import type { AppAuthenticationClient } from './modules/app-authentication/service/app-authentication-client/app-authentication-client';
import { addImowApiResourceHint } from './modules/app-network/service/imow-api/imow-api-resource-hint';
import { addImowSignalrResourceHint } from './modules/app-network/service/imow-signalr-client/imow-signalr-resource-hint';
import { reportWebVitals } from './web-vitals';

// uncomment to temporarily run the app with local mocks. NEVER commit!
// setupAppLocalMocks();

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    /**
     * Enable testers to identify the concrete app version.
     */
    imowAppVersion: string;
    /**
     * Global functionality provided in `index.html`.
     */
    imowApp?: {
      /**
       * Transitions out the initial progress indicator added in `index.html`
       * to provide quick user feedback.
       *
       * Called to replace the initial vanilla HTML/JS progress indicator
       * with its React variant when the React app is loaded.
       */
      removeAppIndexProgressIndicator: () => void;
      /**
       * Removes the rudimentary error handler added in `index.html`
       * to provide user feedback in case the React app cannot be loaded.
       *
       * Called to replace the initial vanilla HTML/JS error handler
       * with its React variant when the React app is loaded.
       */
      removeAppIndexErrorHandler: () => void;
    };
  }
}
if (process.env.REACT_APP_VERSION == null) {
  throw new TypeError('REACT_APP_VERSION is not defined in environment.');
}
window.imowAppVersion = process.env.REACT_APP_VERSION;

async function loadAndRenderReactAppLazily(
  appInitRootModuletContainer: ModuletContainer<AppInitModuletExports>,
): Promise<void> {
  const { createAppInitRoot } = await import(
    /* webpackChunkName: "app-init", webpackPreload: true */ './app/app-init-root'
  );
  const AppInitRoot = createAppInitRoot(appInitRootModuletContainer);

  // don't lazy load React as it is automatically split to vendor chunk
  renderReactDom(<AppInitRoot />, document.querySelector('#imow-app-root'));
}

/**
 * Adds resource hints depending on if we have an authenticated user as early
 * as possible.
 *
 * - authenticated: preconnect to iMOW API as early as possible to speed up data fetching.
 * - not authenticated: preload welcome screen image. Due to the large size of
 *   the image preloading here happens too late, so that for slow connections (3G)
 *   the image is still loading when the user gets to the welcome screen.
 *   This can be prevented by preloading in `index.html`: this speeds up
 *   the first visit of a user (image is already loaded once welcome screen
 *   is displayed). But for a returning which is still authenticated the app
 *   loads longer because the image is still preloaded but never displayed.
 *   The latter only applies when no service worker is active that can serve
 *   the image from cache (which should be the case for modern browsers).
 *
 * @param authenticationClient
 */
function addResourceHintsDependingOnAuthentication(
  authenticationClient: AppAuthenticationClient,
): void {
  if (!canUseDom()) return;
  const removeHandler = authenticationClient.onUser.add((user) => {
    removeHandler();
    if (user) {
      addImowSignalrResourceHint();
      addImowApiResourceHint();
    } else {
      addImowWelcomePageResourceHints();
    }
  });
}

async function start(): Promise<void> {
  /*
  Eagerly create init modulet first with initial services while
  React app is pre-laded lazily in the background.
  - allow service worker to load (update) simultaneously
  - start initializing authentication (load login tokens from storage) so
    authentication status is ideally already known when rendering app-init
   */
  const appInitRootModuletContainer = createAppInitRootModuletContainer();

  addResourceHintsDependingOnAuthentication(
    appInitRootModuletContainer.getValue('authenticationClient'),
  );

  try {
    await loadAndRenderReactAppLazily(appInitRootModuletContainer);
  } catch {
    /*
    ensure service worker updates if loading React app fails.
    if loading the React app succeeds we let its UI handle the update.
    */
    const serviceWorker = appInitRootModuletContainer.getValue('serviceWorker');
    serviceWorker.setAutoUpdate(true);
  }
}

void start();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
