export type PromiseResult<T extends PromiseLike<unknown>> =
  T extends PromiseLike<infer U> ? U : never;

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export function isPromiseLike<T = void>(
  value: unknown,
): value is PromiseLike<T> {
  return (
    value != null && typeof (value as { then: unknown }).then === 'function'
  );
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export function isPromise<T = void>(value: unknown): value is Promise<T> {
  return (
    isPromiseLike(value) &&
    typeof (value as unknown as { catch: unknown }).catch === 'function'
  );
}
