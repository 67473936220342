import type { UnionToIntersection } from 'utility-types';
import type { AlwaysIntersectable } from '../extras-typescript/intersection';
import type { HasAnyRequiredKey } from '../extras-typescript/keys';
import { isPromise } from '../extras-typescript/promise';
import type {
  ModuletExportsBase,
  ModuletExportsNone,
  ModuletImportsBase,
} from './modulet-imports-exports';
import {
  assignExports,
  assignExportsToScope,
  deleteExports,
  toReadonlyScope,
} from './modulet-scope';

type ActivateSync = (() => void) | (() => Deactivate);
type ActivateAsync = (() => Promise<void>) | (() => Promise<Deactivate>);
type Activate = ActivateSync | ActivateAsync;
type DeactivateSync = () => void;
type DeactivateAsync = () => Promise<void>;
type Deactivate = DeactivateSync | DeactivateAsync;

export type ModuletInstanceExports<TExports extends ModuletExportsBase> = {
  exports: TExports;
  activate?: Activate;
};
export type ModuletInstanceSideEffect = {
  exports?: undefined;
  activate: Activate;
};

/**
 * @overview
 * A [modulet]{@link ModuletInstance} is defined by its exported values and a lifecycle.
 * It is created by a [factory]{@link CreateModulet} function, optionally taking an object of
 * imports (which are `export`s of other modulets).
 * A modulet is registered and runs in a {@link ModuletContainer}
 *
 * ## Exports
 *
 * The values exported by a modulet can be anything but are typically
 * **instances**, e.g. of services. This is in contrast to ECMAScript modules which
 * export static symbols without a runtime characteristic (e.g. a class but
 * not an instantiated class. It is possible to keep a singleton
 * instance of a class referenced in a module but this leads to issues
 * with issues or when the module is loaded multiple times by the JS runtime).
 * The modulet factory knows how to create those instances and wire them
 * if they have dependencies to each other, like adding event listeners,
 * setting an instance as a collaborator of another instance.
 * A modulet may [export]{@link ModuletInstanceExports} a single or multiple
 * values. Every value is identified by a name which equals the property
 * name in the `exports` object. It is also possible to export no values,
 * to only cause side effects (see below).
 *
 * ## Lifecycle
 *
 * The instances exported by modulet typically require a lifecycle, e.g.
 * to initialize a service (load initial state, add subscriptions) and
 * dispose it after usage (free allocated resources like subscriptions,
 * close sockets). Therefore a module supports an
 * [`activate()`]{@link ModuletInstance#activate} function which can be sync
 * or async and returns a `deactivate()` function if disposal is necessary.
 *
 * ## Side effects
 *
 * A modulet may also just define [side effects]{@link ModuletInstanceSideEffect}
 * via its `activate()` function and not export any values. This typically
 * involves the factory function to take the `export`ed value of another
 * modulet as a named parameter (with the name equalling the name of the export)
 * and performing the side effect during activation (e.g. adding plugin
 * functionality to the instance) and undoing it in deactivation
 * (e.g. removing the plugin functionality).
 */
export type ModuletInstance<TExports extends ModuletExportsBase> =
  keyof TExports extends never
    ? ModuletInstanceSideEffect
    : ModuletInstanceExports<TExports>;
type ModuletInstanceShape<TExports extends ModuletExportsBase> = {
  exports?: TExports | undefined;
  activate?: Activate;
};

export type CreateModulet<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
> = TImports extends undefined
  ? () => ModuletInstance<TExports>
  : HasAnyRequiredKey<TImports> extends true
  ? (imports: TImports) => ModuletInstance<TExports>
  : (imports?: TImports) => ModuletInstance<TExports>;

export type CreateModuletAny =
  /* eslint-disable @typescript-eslint/no-explicit-any */
  | ((imports: any) => ModuletInstanceShape<ModuletExportsBase>)
  | ((imports?: any) => ModuletInstanceShape<ModuletExportsBase>)
  /* eslint-enable @typescript-eslint/no-explicit-any */
  | (() => ModuletInstanceShape<ModuletExportsBase>);

export type CreateModuletNone = () => ModuletInstanceShape<ModuletExportsNone>;

export type ImportsOf<TCreate extends CreateModuletAny> =
  Parameters<TCreate>[0];
export type ExportsOf<TCreate extends CreateModuletAny> =
  ReturnType<TCreate>['exports'] extends undefined
    ? ModuletExportsNone
    : NonNullable<ReturnType<TCreate>['exports']>;

export type ImportsOfAll<
  TCreateModuletOrMultiple extends
    | CreateModuletAny
    | CreateModuletAny[]
    | readonly CreateModuletAny[],
> = ImportsOf<
  TCreateModuletOrMultiple extends
    | CreateModuletAny[]
    | readonly CreateModuletAny[]
    ? UnionToIntersection<TCreateModuletOrMultiple[number]>
    : TCreateModuletOrMultiple
>;
export type ExportsOfAll<
  TCreateModuletOrMultiple extends
    | CreateModuletAny
    | CreateModuletAny[]
    | readonly CreateModuletAny[]
    | undefined,
> =
  // exports cannot be `undefined` so `AlwaysIntersectable` ensures we get an
  // `object` (= `ModuletExportsNone`) instead
  AlwaysIntersectable<
    TCreateModuletOrMultiple extends
      | CreateModuletAny[]
      | readonly CreateModuletAny[]
      ? UnionToIntersection<ExportsOf<TCreateModuletOrMultiple[number]>>
      : TCreateModuletOrMultiple extends CreateModuletAny
      ? ExportsOf<TCreateModuletOrMultiple>
      : never
  >;

export type ModuletKey<
  TImports extends ModuletImportsBase = ModuletImportsBase,
  TExports extends ModuletExportsBase = ModuletExportsBase,
> = CreateModulet<TImports, TExports> | PropertyKey;

export type ModuletMap<
  TAllImports extends ModuletImportsBase = ModuletImportsBase,
  TAllExports extends ModuletExportsBase = ModuletExportsBase,
> = Map<
  ModuletKey<TAllImports, TAllExports>,
  Modulet<TAllImports, TAllExports>
>;

export function createModuletMap<
  TAllImports extends ModuletImportsBase = ModuletImportsBase,
  TAllExports extends ModuletExportsBase = ModuletExportsBase,
>(): ModuletMap<TAllImports, TAllExports> {
  return new Map<
    ModuletKey<TAllImports, TAllExports>,
    Modulet<TAllImports, TAllExports>
  >();
}

export function getModuletMapAllExports<
  TAllImports extends ModuletImportsBase = ModuletImportsBase,
  TAllExports extends ModuletExportsBase = ModuletExportsBase,
>(modulets: ModuletMap<TAllImports, TAllExports>): TAllExports {
  const allExports = {} as TAllExports;
  for (const [_key, modulet] of modulets) {
    if (!modulet.instance?.exports) continue;
    assignExports(allExports, modulet.instance.exports);
  }
  return allExports;
}

type ModuletShape<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
> = {
  key: ModuletKey<TImports, TExports>;
  create: CreateModulet<TImports, TExports>;
  instance?: ModuletInstance<TExports>;
  whenActivated?: Promise<void>;
  deactivate?: Deactivate;
  whenDeactivated?: Promise<void>;
};

type ModuletRegistered<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
> = {
  key: ModuletKey<TImports, TExports>;
  create: CreateModulet<TImports, TExports>;
  instance?: undefined;
  whenActivated?: undefined;
  deactivate?: undefined;
  whenDeactivated?: undefined;
};

type ModuletInstantiated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
> = {
  key: ModuletKey<TImports, TExports>;
  create: CreateModulet<TImports, TExports>;
  instance: ModuletInstance<TExports>;
  whenActivated?: undefined;
  deactivate?: undefined;
  whenDeactivated?: undefined;
};

function isModuletInstantiated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  value: ModuletShape<TImports, TExports>,
): value is ModuletInstantiated<TImports, TExports> {
  return value.instance != null;
}

export function assertModuletInstantiated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  value: ModuletShape<TImports, TExports>,
): asserts value is ModuletInstantiated<TImports, TExports> {
  if (!isModuletInstantiated(value)) {
    throw new TypeError(
      `Expected modulet to be instantiated: ${JSON.stringify(value)}`,
    );
  }
}

function asModuletInstantiated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  value: ModuletShape<TImports, TExports>,
): ModuletInstantiated<TImports, TExports> {
  if (!isModuletInstantiated(value)) {
    throw new TypeError(
      `Expected modulet to be instantiated: ${JSON.stringify(value)}`,
    );
  }
  return value;
}

export type ModuletActivated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
> = {
  key: ModuletKey<TImports, TExports>;
  create: CreateModulet<TImports, TExports>;
  instance: ModuletInstance<TExports>;
  whenActivated: Promise<void>;
  deactivate: Deactivate;
  whenDeactivated: Promise<void>;
  isInitialActivation: boolean;
};

function isModuletActivated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  value: ModuletShape<TImports, TExports>,
): value is ModuletActivated<TImports, TExports> {
  return value.whenActivated != null;
}

function asModuletActivated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  value: ModuletShape<TImports, TExports>,
): ModuletActivated<TImports, TExports> {
  if (!isModuletActivated(value)) {
    throw new TypeError(
      `Expected modulet to be activated: ${JSON.stringify(value)}`,
    );
  }
  return value;
}

export type ModuletDeactivated<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
> = {
  key: ModuletKey<TImports, TExports>;
  create: CreateModulet<TImports, TExports>;
  instance: ModuletInstance<TExports>;
  whenActivated?: undefined;
  deactivate?: undefined;
  whenDeactivated: Promise<void>;
};
export type Modulet<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
> =
  | ModuletRegistered<TImports, TExports>
  | ModuletInstantiated<TImports, TExports>
  | ModuletActivated<TImports, TExports>
  | ModuletDeactivated<TImports, TExports>;
const resolvedPromise = Promise.resolve();

function instantiateModulet<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  modulets: ModuletMap<TImports, TExports>,
  scope: NonNullable<TImports>,
  create: CreateModulet<TImports, TExports>,
  key: ModuletKey<TImports, TExports> = create,
): ModuletInstantiated<TImports, TExports> {
  const existing = modulets.get(key);
  if (existing) return asModuletInstantiated(existing);

  const moduletRegistered = {
    key,
    create,
  };
  modulets.set(key, moduletRegistered);

  const instance = create(toReadonlyScope(scope));
  const moduletInstantiated = {
    key,
    create,
    instance,
  };
  modulets.set(key, moduletInstantiated);

  if (instance.exports) {
    assignExportsToScope(scope, instance.exports);
  }

  return moduletInstantiated;
}

function makeDeactivateModulet(
  maybeDeactivate: ReturnType<Activate> | undefined,
): { deactivate: () => void | Promise<void>; whenDeactivated: Promise<void> } {
  /* eslint-disable @typescript-eslint/no-invalid-void-type */
  let resolveWhenDeactivated: (value: void | undefined) => void;
  let rejectWhenDeactivated: (value: void | undefined) => void;

  const whenDeactivated = new Promise<void>((resolve, reject) => {
    resolveWhenDeactivated = resolve;
    rejectWhenDeactivated = reject;
  });

  const deactivate = (): void | Promise<void> => {
    let deactivatePromise: Promise<void> | undefined;
    if (maybeDeactivate == null) {
      deactivatePromise = undefined;
    } else if (typeof maybeDeactivate === 'function') {
      deactivatePromise = maybeDeactivate() as Promise<void> | undefined;
    } else {
      deactivatePromise = maybeDeactivate.then(
        (deactivateAfterAsyncActivation: Deactivate | void) => {
          return (deactivateAfterAsyncActivation as Deactivate | undefined)?.();
        },
      );
    }
    /* eslint-enable @typescript-eslint/no-invalid-void-type */

    if (!deactivatePromise) {
      // resolve as next microtask so other potential deactivations of container modulets can be called
      void resolvedPromise.then(resolveWhenDeactivated);
      return;
    }
    return deactivatePromise.then(
      resolveWhenDeactivated,
      rejectWhenDeactivated,
    );
  };

  return {
    deactivate,
    whenDeactivated,
  };
}

// eslint-disable-next-line max-lines-per-function
export function activateModulet<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  modulets: ModuletMap<TImports, TExports>,
  scope: NonNullable<TImports>,
  create: CreateModulet<TImports, TExports>,
  key: ModuletKey<TImports, TExports> = create,
): ModuletActivated<TImports, TExports> {
  const existing = modulets.get(key);
  if (existing && isModuletActivated(existing)) {
    existing.isInitialActivation = false;
    return existing;
  }

  const moduletInstantiated = existing
    ? asModuletInstantiated(existing)
    : instantiateModulet(modulets, scope, create, key);

  let errorSyncFromActivate: Error | undefined;
  let maybeDeactivate: // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void | Promise<void> | DeactivateSync | Promise<Deactivate> | undefined;
  try {
    maybeDeactivate = moduletInstantiated.instance.activate?.();
  } catch (error) {
    errorSyncFromActivate = error as Error;
  }

  let whenActivated: Promise<void>;
  if (errorSyncFromActivate) {
    whenActivated = Promise.reject(errorSyncFromActivate);
  } else if (maybeDeactivate != null && isPromise(maybeDeactivate)) {
    whenActivated = maybeDeactivate.then(() => undefined);
  } else {
    whenActivated = resolvedPromise;
  }

  const { deactivate, whenDeactivated } =
    makeDeactivateModulet(maybeDeactivate);

  const moduletActivated: ModuletActivated<TImports, TExports> = {
    key,
    create,
    whenActivated,
    instance: moduletInstantiated.instance,
    deactivate,
    whenDeactivated,
    isInitialActivation: true,
  };
  modulets.set(key, moduletActivated);

  return moduletActivated;
}

export function deactivateModulet<
  TImports extends ModuletImportsBase,
  TExports extends ModuletExportsBase,
>(
  modulets: ModuletMap<TImports, TExports>,
  scope: TExports,
  key: ModuletKey<TImports, TExports>,
): ModuletDeactivated<TImports, TExports> | undefined {
  const modulet = modulets.get(key);
  if (modulet == null) {
    return undefined;
  }
  const moduletActivated = asModuletActivated(modulet);

  modulets.delete(key);
  if (moduletActivated.instance.exports) {
    deleteExports(scope, moduletActivated.instance.exports);
  }

  void moduletActivated.deactivate();

  return {
    key: moduletActivated.key,
    create: moduletActivated.create,
    instance: moduletActivated.instance,
    whenDeactivated: moduletActivated.whenDeactivated,
  };
}
