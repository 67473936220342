import type { AppAuthenticationClient } from '../app-authentication-client/app-authentication-client';
import { createAppAuthenticationClient } from '../app-authentication-client/app-authentication-client';
import { appAuthenticationClientCustomerRoleConfig } from './app-authentication-client-customer-role-config';
import { appAuthenticationClientServiceRoleConfig } from './app-authentication-client-service-role-config';
import type { AuthenticationRole } from './app-authentication-role';
import { getAuthenticationRoleFromUrl } from './app-authentication-role-from-url';

export type AppAuthenticationRoleBasedClient = {
  readonly role: AuthenticationRole;
} & AppAuthenticationClient;

export function createAppAuthenticationRoleBasedClient(): AppAuthenticationRoleBasedClient {
  const role = getAuthenticationRoleFromUrl(window);
  const settings =
    role === 'service'
      ? { settings: appAuthenticationClientServiceRoleConfig }
      : { settings: appAuthenticationClientCustomerRoleConfig };
  return {
    role,
    ...createAppAuthenticationClient(settings),
  };
}
