import type { CreateModulet } from '../../../base/modulet/modulet';
import type { ModuletContainer } from '../../../base/modulet/modulet-container';
import { createModuletContainerOfActivated } from '../../../base/modulet/modulet-container';
import type { ModuletImportsNone } from '../../../base/modulet/modulet-imports-exports';
import type { AppAuthenticationModuletExports } from '../../../modules/app-authentication/app-authentication-modulet';
import { createAppAuthenticationModulet } from '../../../modules/app-authentication/app-authentication-modulet';
import type { AppServiceWorkerModuletExports } from '../../modules/app-service-worker/service/app-service-worker-modulet';
import { createAppServiceWorkerModulet } from '../../modules/app-service-worker/service/app-service-worker-modulet';

export type AppInitModuletExports = AppServiceWorkerModuletExports &
  AppAuthenticationModuletExports;

export type AppInitModuletFactories = [
  CreateModulet<ModuletImportsNone, AppServiceWorkerModuletExports>,
  CreateModulet<ModuletImportsNone, AppAuthenticationModuletExports>,
];

export const appInitModuletFactories: AppInitModuletFactories = [
  /*
  trigger lazy loading and registering the service worker **first** to ensure its update is
  triggered. if this would be performed after the authentication and
  authentication encounters an unhandled error a faulty service worker could
  never be updated.
  */
  createAppServiceWorkerModulet,
  createAppAuthenticationModulet,
];

/**
 * Creates the root modulet container to provide initial application wide services.
 *
 * ## Create as singleton
 *
 * This root modulet container needs needs to be created once as a singleton.
 * Therefore it cannot be called in a React `render()` function, as these
 * are executed multiple times by React.
 * Only as a singleton can ensure the container ensure that services are only
 * activated and exist once. This even allows to declaratively activate a service
 * in the modulet container via an [<AppChildModuletProvider />]{@link {@link AppChildModuletProvider}
 * in a React `render()` function: even though the `render()` function
 * executes multiple time the container guarantees that the service only
 * exists once. This could also be achieved via `useEffect()` but this would
 * delay the service instantiation after the render which can be problematic
 * if services are needed during first render (and you would have to wait
 * for the first effect run to have occurred) and it makes the app slower
 * as potential data fetching that occurs during service activation would have
 * to wait for the first render. By activating services (and fetching data)
 * before render the app runs faster.
 *
 * ## Create early in application lifecycle
 *
 * This should be executed as early as possible during the application lifecycle
 * so the initial services are activated and ready as soon as possible,
 * with authentication being the main reason: any previously authenticated
 * user is loaded as early as possible so the app can decide quicker if
 * the welcome screen needs to be displayed or the authenticated user can
 * use the application itself.
 *
 * ## Init modulet and main modulet
 *
 * Due to it being created first and constituting initial services
 * this modulet container is also the root modulet container.
 * It only contains the services required during app init.
 *
 * Additional [main services]{@link AppMainModuletExports} needed
 * for the whole application are added (as a child modulet container)
 * later when the [main application]{@link AppMain}
 * is presented to an authenticated user.
 *
 * ## Child modulets for code split child modules of the app
 *
 * The main application consists of child modules which are code split
 * and lazy loaded. These child modules have a `…Root` React component
 * that declare {@link AppChildModuletProvider} which creates a child
 * modulet container (of the main modulet container) which manages the
 * services or side-effects (e.g.
 * adding a Redux reducer and/or saga to the global Redux store provided
 * in {@link AppMainModuletExports}) which are needed only in this child module.
 */
export function createAppInitRootModuletContainer(
  moduletFactories: AppInitModuletFactories = appInitModuletFactories,
): ModuletContainer<AppInitModuletExports> {
  return createModuletContainerOfActivated(moduletFactories);
}
