export function assertNonNullable<T>(
  value: T,
  valueName: string,
): asserts value is NonNullable<T> {
  if (value == null) {
    throw new TypeError(`Expected value to be defined: ${valueName}`);
  }
}

export function asNonNullable<T>(value: T, valueName: string): NonNullable<T> {
  assertNonNullable(value, valueName);
  return value;
}
