type ResourceHintType =
  | 'dns-prefetch'
  | 'preconnect'
  | 'prefetch'
  | 'preload'
  | 'prerender';

type ResourceHintOptionsBase<TType extends ResourceHintType> = {
  id?: string;
  type: TType | TType[];
  href: string;
  crossorigin?: 'anonymous' | 'use-credentials' | undefined;
};

type ResourceHintOptionsAs =
  | { as: 'audio' }
  | { as: 'video' }
  | {
      as: 'image';
      imageSrcSet?: string;
      imageSizes?: string;
    }
  | { as: 'document' }
  | { as: 'script' }
  | { as: 'font' };

export type ResourceHintOptions<TType extends ResourceHintType> = TType extends
  | 'prefetch'
  | 'preload'
  ? ResourceHintOptionsBase<'prefetch' | 'preload'> & ResourceHintOptionsAs
  : ResourceHintOptionsBase<'dns-prefetch' | 'preconnect' | 'prerender'> & {
      as?: undefined;
    };

export function createResourceHintElement<TType extends ResourceHintType>(
  options: ResourceHintOptions<TType>,
): HTMLLinkElement {
  const { id, type, href, crossorigin, as } = options;
  const typeString = Array.isArray(type) ? type.join(' ') : type;
  const resourceHintElement = document.createElement('link');
  resourceHintElement.setAttribute('rel', typeString);
  resourceHintElement.setAttribute('href', href);

  if (id) {
    resourceHintElement.setAttribute('id', id);
  }

  if (crossorigin) {
    resourceHintElement.setAttribute('crossorigin', crossorigin);
  }

  if (as) {
    resourceHintElement.setAttribute('as', as);
    // cannot destructure because then TypeScript cannot refine discriminate union by `as`
    /* eslint-disable unicorn/consistent-destructuring */
    if (as === 'image') {
      if (options.imageSrcSet) {
        resourceHintElement.setAttribute('imagesrcset', options.imageSrcSet);
      }
      if (options.imageSizes) {
        resourceHintElement.setAttribute('imagesizes', options.imageSizes);
      }
    }
    /* eslint-enable unicorn/consistent-destructuring */
  }
  return resourceHintElement;
}

export function addResourceHint<TType extends ResourceHintType>(
  options: ResourceHintOptions<TType>,
): HTMLLinkElement {
  if (options.id) {
    const existing = document.head.querySelector(`#${options.id}`);
    if (existing != null) return existing as HTMLLinkElement;
  }
  const resourceHintElement = createResourceHintElement(options);
  document.head.append(resourceHintElement);
  return resourceHintElement;
}
