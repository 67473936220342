import decodeJsonWebToken from 'jwt-decode';
import type { UserManagerSettings } from 'oidc-client';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import nameof from 'ts-nameof.macro';
import type { EventHandlers } from '../../../../base/event/event-handlers';
import { createEventHandlersSync } from '../../../../base/event/event-handlers';
import type { EventHandlersWithStateOf } from '../../../../base/event/event-handlers-state';
import { withState } from '../../../../base/event/event-handlers-state';
import type { PromiseSettlable } from '../../../../base/extras-javascript/promise-settlable';
import { createPromiseSettlable } from '../../../../base/extras-javascript/promise-settlable';
import { asError } from '../../../../base/extras-typescript-asserts/type-error-asserts';
import { assertString } from '../../../../base/extras-typescript-asserts/type-string-asserts';
import type { User } from '../../model/user';
import { AppAuthenticationClientError } from './app-authentification-client-error';
import type { StihlRefreshToken } from './stihl-authentication-tokens';
import { isRefreshTokenExpired } from './stihl-authentication-tokens';

// 3rd party API does not follow conventions
// eslint-disable-next-line @typescript-eslint/naming-convention
const userManagerRedirectArgs = { useReplaceToNavigate: true };

type AsyncFunction<TResult, TArgs extends unknown[]> = (
  ...args: TArgs
) => Promise<TResult>;

function withAsyncResultErrorCallbacks<
  TResult,
  TError extends Error,
  TArgs extends unknown[],
>(
  asyncFunction: AsyncFunction<TResult, TArgs>,
  onResult?: (value: TResult) => void,
  onError?: (error: TError) => void,
): AsyncFunction<TResult | undefined, TArgs> {
  return async (...args) => {
    let result: TResult | undefined;
    if (!onError) {
      result = await asyncFunction(...args);
    } else {
      try {
        result = await asyncFunction(...args);
      } catch (error) {
        onError(asError(error));
        return;
      }
    }

    onResult?.(result);

    return result;
  };
}

function createUserManagerSettings(
  storage: Storage,
  settings: UserManagerSettings,
): UserManagerSettings {
  return {
    userStore: new WebStorageStateStore({ store: storage }),
    stateStore: new WebStorageStateStore({ store: storage }),
    ...settings,

    metadata: {
      ...settings.metadata,
    },
  };
}

function isUserLoggedIn(user: User | null | undefined): boolean {
  return user != null && !user.expired;
}

async function login(userManager: UserManager): Promise<void> {
  const user: User | null = await userManager.getUser();

  if (isUserLoggedIn(user) || isRedirectWithCode(window.location)) {
    return;
  }

  if (process.env.NODE_ENV === 'development' && isUserLoginRefreshable(user)) {
    console.debug(
      '[AppAuthenticationClient] Login invoked while expired user is refreshable',
    );
  }

  try {
    await userManager.signinRedirect(userManagerRedirectArgs);
  } catch (error) /* istanbul ignore next – report traceable error, no error expected */ {
    await userManager.clearStaleState();
    throw AppAuthenticationClientError.fromCause(
      asError(error),
      'Failed to login',
    );
  }
}

function isUserLoginRefreshable(user: User | null | undefined): boolean {
  return (
    user?.refresh_token != null &&
    !isRefreshTokenExpired(
      decodeJsonWebToken<StihlRefreshToken>(user.refresh_token),
    )
  );
}

// TOod: Check if the library provides event if refresh token is revoked - else ticket
async function refreshLogin(
  userManager: UserManager,
  settings: UserManagerSettings,
): Promise<void> {
  const user: User | null = await userManager.getUser();
  if (!isUserLoginRefreshable(user)) {
    await userManager.removeUser();
    return;
  }

  try {
    await userManager.signinSilent({
      scope: settings.scope,
    });
  } catch /* istanbul ignore next – report traceable error, no error expected */ {
    await userManager.removeUser();
  }
}

async function logout(
  userManager: UserManager,
  settings: UserManagerSettings,
): Promise<void> {
  const user: User | null = await userManager.getUser();
  if (!isUserLoggedIn(user)) {
    throw new AppAuthenticationClientError(
      'Cannot logout a invalid or not logged in user',
    );
  }

  const signoutArgs = {
    extraQueryParams: {
      goto: settings.post_logout_redirect_uri,
    },
    ...userManagerRedirectArgs,
  };

  try {
    await userManager.signoutRedirect(signoutArgs);
  } catch (error) /* istanbul ignore next – report traceable error, no error expected */ {
    throw AppAuthenticationClientError.fromCause(
      asError(error),
      'Failed to logout',
    );
  }
}

function changeUrlWithoutRedirect(url: string): void {
  // eslint-disable-next-line no-restricted-globals
  history.replaceState({}, '', url);
}

/**
 * Loads persisted user state and resumes from any pending (login etc.) redirects.
 * Needs to be executed when the app loads.
 */
async function loadAndResumeFromRedirects(
  userManager: UserManager,
  settings: UserManagerSettings,
): Promise<User | undefined> {
  let user = (await userManager.getUser()) ?? undefined;

  if (user && isUserLoggedIn(user)) {
    return user;
  }

  const isResumeFromLoginRedirect = isLoginRedirect(window, user);

  if (isResumeFromLoginRedirect) {
    try {
      user = await userManager.signinCallback();

      assertString(settings.redirect_uri, nameof.full(settings.redirect_uri));

      /**
       * Remove the code url query parameter after login.
       * This leads to an clean url which is seen by the user. This will also
       * prevent processing the login code a second time if the user gets invalid
       * for e.g. if the access token and the refresh token are up to running out.
       */
      changeUrlWithoutRedirect(settings.redirect_uri);

      return user;
    } catch (error) {
      await userManager.clearStaleState();

      const failedOperation = 'login';
      throw AppAuthenticationClientError.fromCause(
        asError(error),
        `Failed to resume after redirect for ${failedOperation}`,
      );
    }
  }

  return undefined;
}

/**
 * Inspects the `location` to determine if the authentication service redirected
 * back to us with a `code`.
 */
function isRedirectWithCode(location: Location): boolean {
  return location.search.includes('code=');
}

function isLoginRedirect(window: Window, user: User | undefined): boolean {
  return !isUserLoggedIn(user) && isRedirectWithCode(window.location);
}

export type AuthenticationClientStatus =
  | 'loading'
  | 'loggingOut'
  | 'loggedOut'
  | 'loggingIn'
  | 'loggedIn';
type Dispose = () => void;
type HandleUser = (user: User | undefined) => void;
type HandleError = (error: AppAuthenticationClientError) => void;
type HandleAuthenticationClientStatus = (
  status: AuthenticationClientStatus,
) => void;

export type AppAuthenticationClientOptions = {
  settings: UserManagerSettings;
};

export type AppAuthenticationClient = {
  load: () => Promise<User | undefined>;
  reload: () => Promise<User | undefined>;
  /**
   * Called everytime an authenticated user session is established
   * (e.g. after completing a login flow or loading an existing user)
   * or a session is terminated.
   *
   * The user's token might be expired when an existing user is loaded
   * from client side storage.
   */
  onUser: EventHandlersWithStateOf<HandleUser, undefined>;
  /**
   * called everytime the Authentication client changes it status which is of
   * interest for using it. This includes `loggingIn`, `loggedIn`, `loggingOut`
   *  and `loading`.
   */
  onStatus: EventHandlersWithStateOf<
    HandleAuthenticationClientStatus,
    undefined
  >;
  getUser: () => Promise<User>;
  getIsUserLoggedIn: () => Promise<boolean>;
  getAccessToken: () => Promise<string>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  dispose: () => void;
  /**
   * Called for all sync and async errors.
   */
  onError: EventHandlers<[error: AppAuthenticationClientError], void>;
};

// eslint-disable-next-line max-lines-per-function
export function createAppAuthenticationClient({
  settings,
}: AppAuthenticationClientOptions): AppAuthenticationClient {
  /* Operations has to wait until the token refresh is done since the access
   * token could be invalid during the refresh. To ensure that this does not
   * happen the currentUserPromise should wait with the resolve until the
   * refresh is done. */
  let isWaitingForRefresh = false;

  /*
   * user promise ensures that the caller of `AppAuthenticationClient.getUser` does
   * get only the user and does not resolve or create a new promise as long as
   * an important operation like logout or refresh is going on
   */
  let currentUserPromise: PromiseSettlable<User> | undefined;

  function resolveUserPromise(user: User): void {
    currentUserPromise?.resolve(user);
    currentUserPromise = undefined;
  }

  function rejectUserPromise(error: Error): void {
    currentUserPromise?.reject(error);
    currentUserPromise = undefined;
  }

  const onAuthenticationClientStatus = withState(
    createEventHandlersSync<HandleAuthenticationClientStatus>(),
  );

  const onUserEventHandlers = withState(createEventHandlersSync<HandleUser>());
  const onErrorEventHandlers = createEventHandlersSync<HandleError>();

  let { userManager, dispose: disposeUserManager } =
    createUserManagerWithEvents();

  // eslint-disable-next-line max-lines-per-function
  function createUserManagerWithEvents(): {
    userManager: UserManager;
    dispose: Dispose;
  } {
    const _userManager: UserManager = new UserManager(
      createUserManagerSettings(window.localStorage, settings),
    );

    function refreshTokenWithErrorCallbacks(): void {
      void withAsyncResultErrorCallbacks(
        async () => {
          isWaitingForRefresh = true;
          await refreshLogin(_userManager, settings);
          isWaitingForRefresh = false;
        },
        undefined,
        onErrorEventHandlers.invoke,
      )();
    }

    // place all here since they are needed here
    /* eslint-disable unicorn/consistent-function-scoping */
    function handleRefreshError(error: Error): void {
      onErrorEventHandlers.invoke(error);
      rejectUserPromise(error);
      isWaitingForRefresh = false;
    }

    function handleUserLoaded(user: User): void {
      onUserEventHandlers.invoke(user);
      resolveUserPromise(user);
      isWaitingForRefresh = false;
    }

    function handleUserUnloaded(): void {
      onUserEventHandlers.invoke(undefined);
    }
    /* eslint-enable unicorn/consistent-function-scoping */

    function disposeOnUserEvent(): void {
      _userManager.events.removeUserLoaded(handleUserLoaded);
      _userManager.events.removeUserUnloaded(handleUserUnloaded);
    }

    function disposeRefreshTokenEvents(): void {
      _userManager.events.removeAccessTokenExpiring(
        refreshTokenWithErrorCallbacks,
      );
      _userManager.events.removeAccessTokenExpired(
        refreshTokenWithErrorCallbacks,
      );
      _userManager.events.removeSilentRenewError(handleRefreshError);
    }

    // activate refreshing token
    _userManager.events.addAccessTokenExpired(refreshTokenWithErrorCallbacks);
    _userManager.events.addAccessTokenExpiring(refreshTokenWithErrorCallbacks);
    _userManager.events.addSilentRenewError(handleRefreshError);

    // activate onUser
    _userManager.events.addUserLoaded(handleUserLoaded);
    _userManager.events.addUserUnloaded(handleUserUnloaded);
    return {
      userManager: _userManager,
      dispose: () => {
        isWaitingForRefresh = false;
        rejectUserPromise(new Error('Authentication client was reloaded'));
        disposeOnUserEvent();
        disposeRefreshTokenEvents();
      },
    };
  }

  async function getUserPromise(): Promise<User> {
    const user = await userManager.getUser();
    if (
      user &&
      isUserLoggedIn(user) &&
      !isWaitingForRefresh &&
      onAuthenticationClientStatus.get() !== 'loggingOut'
    ) {
      return Promise.resolve(user);
    }
    // wait until the currentUserPromise resolves (is in refresh state)
    if (currentUserPromise) {
      return currentUserPromise.promise;
    }
    currentUserPromise = createPromiseSettlable<User>();
    return currentUserPromise.promise;
  }

  return {
    load: withAsyncResultErrorCallbacks(
      async () => {
        onAuthenticationClientStatus.invoke('loading');
        const user = await loadAndResumeFromRedirects(userManager, settings);
        if (user != null) {
          onAuthenticationClientStatus.invoke('loggedIn');
        } else {
          onAuthenticationClientStatus.invoke('loggedOut');
        }
        return user;
      },
      onUserEventHandlers.invoke,
      onErrorEventHandlers.invoke,
    ),
    reload: withAsyncResultErrorCallbacks(
      async () => {
        disposeUserManager();
        ({ userManager, dispose: disposeUserManager } =
          createUserManagerWithEvents());
        onAuthenticationClientStatus.invoke('loading');
        const user = await loadAndResumeFromRedirects(userManager, settings);
        if (user != null) {
          onAuthenticationClientStatus.invoke('loggedIn');
        } else {
          onAuthenticationClientStatus.invoke('loggedOut');
        }
        return user;
      },
      onUserEventHandlers.invoke,
      onErrorEventHandlers.invoke,
    ),
    dispose: () => disposeUserManager(),
    getIsUserLoggedIn: async () => {
      const user = await userManager.getUser();
      return user != null && isUserLoggedIn(user);
    },
    onUser: onUserEventHandlers,
    onStatus: onAuthenticationClientStatus,
    getUser: async () => getUserPromise(),
    getAccessToken: async () => {
      const user = await getUserPromise();
      return user.access_token;
    },
    login: withAsyncResultErrorCallbacks(
      async () => {
        onAuthenticationClientStatus.invoke('loggingIn');
        return login(userManager);
      },
      undefined,
      onErrorEventHandlers.invoke,
    ),
    logout: withAsyncResultErrorCallbacks(
      async () => {
        onAuthenticationClientStatus.invoke('loggingOut');
        return logout(userManager, settings);
      },
      () => onUserEventHandlers.invoke(undefined),
      onErrorEventHandlers.invoke,
    ),
    onError: onErrorEventHandlers,
  };
}
